import React, {
  useContext,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import _, { get } from "lodash";
import LoadingButton from "@mui/lab/LoadingButton";
import { useFormik } from "formik";
import * as yup from "yup";
import "yup-phone";
import settings from "../../config/settings";
import { client } from "../../common";
import { SnackbarContext } from "../../App";
import { useSelector } from "react-redux";

const validationSchema = yup.object({
  name: yup.string("Please Enter Name").required("Please Enter Name"),
  active: yup.bool().required(),
  description: yup
    .string("Please Enter Description")
    .required("Please Enter Description"),
  price: yup
    .number("Price Must be Integer")
    .integer("Price Must be Integer")
    .required("Please Enter Price"),
});

const DeliveryOptionForm = React.forwardRef(({ item, onSuccess }, ref) => {
  const { showSuccessToast, showErrorToast } = useContext(SnackbarContext);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const userData = useSelector((state) => state.auth.userdata);
  const [error, setError] = useState(null);

  const storeID = useMemo(() => get(userData, "store_id", 0), [userData]);

  useImperativeHandle(
    ref,
    () => {
      return {
        openForm: () => setOpen(true),
        closeForm: () => setOpen(false),
      };
    },
    []
  );

  const createDeliveryPrice = async (payload) => {
    try {
      const endPoint = settings.endpoints.createDeliveryOption;
      setIsLoading(true);
      // setError(null);
      const response = await client.post(endPoint, {
        ...payload,
        status: payload.active ? 1 : 0,
        store_id: storeID,
      });
      //   console.log('Response of create delivery prices------', response);
      if (response.data.success) {
        setOpen(false);
        showSuccessToast(get(response, "data.message"));
        onSuccess();
        // analyticsEvent("Update_Business_Details", {
        //   type: "User added Business Delivery Option",
        // });
      } else {
        showErrorToast(get(response, "data.message", "Server Error!!"));
      }
      console.log("Response of create options-----", response);
      setIsLoading(false);
    } catch (error) {
      console.log("Error in create error----", error);
      setIsLoading(false);
      showErrorToast(get(error, "response.message", "Server Error!!"));
    }
  };

  const updateDeliveryPrice = async (payload) => {
    try {
      const endPoint = settings.endpoints.updateDeliveryOption(item?.id);
      setIsLoading(true);
      // setError(null);
      const response = await client.put(endPoint, {
        ...payload,
        status: payload.active ? 1 : 0,
        store_id: storeID,
      });
      //   console.log('Response of create delivery prices------', response);
      setIsLoading(false);
      if (response.data.success) {
        // const pricesObj = get(response, "data.data", {});
        setOpen(false);
        showSuccessToast(get(response, "data.message"));
        onSuccess();
      } else {
        showErrorToast(get(response, "data.message", "Server Error!!"));
      }
    } catch (error) {
      console.log("Error in create error----", error);
      setIsLoading(false);
      showErrorToast(get(error, "response.message", "Server Error!!"));
    }
  };

  const onSubmit = async (values) => {
    console.log("values", values);
    if (item?.id) {
      updateDeliveryPrice(values);
    } else {
      createDeliveryPrice(values);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: item?.name || "",
      active: item ? item.status : true,
      description: item?.description || "",
      price: item?.price || 0,
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleReset,
    setFieldValue,
  } = formik;

  const handleCheckbox = (e) => {
    const key = e.target.name;
    setFieldValue(key, !values[key], true);
  };

  const handleDialogOpen = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    handleReset();
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleDialogClose}>
      <DialogTitle>Add Delivery Option</DialogTitle>
      <DialogContent>
        <DialogContentText>Add Delivery Option Details</DialogContentText>
        <TextField
          fullWidth
          id="name"
          name="name"
          label="Name"
          variant="standard"
          placeholder="Name"
          value={values.name}
          onChange={handleChange}
          error={touched.name && Boolean(errors.name)}
          helperText={touched.name && errors.name}
          margin="normal"
        />

        <TextField
          fullWidth
          id="description"
          name="description"
          label="Description"
          variant="standard"
          placeholder="Description"
          value={values.description}
          onChange={handleChange}
          error={touched.description && Boolean(errors.description)}
          helperText={touched.description && errors.description}
          margin="normal"
        />

        <TextField
          fullWidth
          id="price"
          name="price"
          label="Price"
          type={"number"}
          variant="standard"
          placeholder="Price"
          value={values.price}
          onChange={handleChange}
          error={touched.price && Boolean(errors.price)}
          helperText={touched.price && errors.price}
          margin="normal"
        />

        <FormControl
          required
          error={touched.active && Boolean(errors.active)}
          component="fieldset"
          sx={{ my: 1 }}
          variant="standard"
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={values.active}
                onChange={handleCheckbox}
                name="active"
              />
            }
            label="Set Active"
          />

          {touched.active && errors.active ? (
            <FormHelperText component={"p"} className="error" variant="caption">
              {errors.active}
            </FormHelperText>
          ) : null}
        </FormControl>

        {error && (
          <Typography component={"p"} className="error" variant="caption">
            {error}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose}>Cancel</Button>
        <LoadingButton
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          disabled={isLoading}
          loading={isLoading}
          style={{ minWidth: 200 }}
          loadingIndicator="Loading…"
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
});

export default DeliveryOptionForm;
