import WarningSign from "./utils/warning-sign.svg";
import "./Dashboard.css";
import _, { get } from "lodash";
import warningStyles from "./svgdesigning.js";
import app_store from "../../images/app_store.png";
import play_store from "../../images/play_store.png";
import QR from "../../images/qr.png";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { client } from "../../common";
import settings from "../../config/settings";
import {
  setBuisnessBasicInfo,
  setServices,
  setStatusList,
  setStoreInfo,
} from "../../redux/action";

function Dashboard() {
  const dispatch = useDispatch();
  // const history = useHistory();
  const user = useSelector((state) => state.auth.userdata);
  const storeInfo = useSelector((state) => state.auth.storeInfo);
  const detail = useSelector((state) => state.auth.bBaicInfo);

  const [haveOrders, setHaveOrders] = useState(false);
  // const [refreshing, setRefreshing] = useState(false);
  // const [refreshLog, setRefreshLog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [offersLength, setOffersLength] = useState(0);

  const getOfferListing = async () => {
    // const { auth } = this.props;
    try {
      const response = await client.get(
        settings.endpoints.getOffersByStore(detail?.id || 0)
      );
      if (response.data?.data?.length) {
        // this.setState({ offersLength: response.data?.data?.length });
        setOffersLength(response.data?.data?.length);
      } else {
        // this.setState({ offersLength: 0 });
        setOffersLength(0);
      }
      // console.log('Response ===', response);
    } catch (error) {
      console.log("Error in list---", error, error.response);
      setOffersLength(0);
    } finally {
    }
  };

  const getStatusList = async () => {
    // const {
    //   authActions: { setStatusList },
    // } = this.props;
    try {
      const endPoint = settings.endpoints.ordersStatusList;
      const response = await client.get(endPoint);
      if (response.data.success) {
        let tempStatus = [];
        const statusList = get(response, "data.data", []);
        statusList?.forEach((i) => {
          if (i?.status_for === "order") {
            tempStatus.push(i);
          }
        });
        dispatch(setStatusList(tempStatus));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getStoreInfo = async () => {
    // const { authActions, auth } = this.props;
    try {
      setLoading(true);
      const { data } = await client.get(
        settings.endpoints.updateStore(get(user, "store_id"))
      );
      if (data.success) {
        let storeInfo1 = get(data, "data[0]", {});
        storeInfo1["services"] = get(data, "data.services", []);
        dispatch(setBuisnessBasicInfo(storeInfo1));
        dispatch(setStoreInfo(data.data));
        let haveOrders1 = false;
        if (get(data, "data.all_orders", 0)) {
          haveOrders1 = true;
        }
        if (get(data, "data.last_month_orders", 0)) {
          haveOrders1 = true;
        }
        if (get(data, "data.last_seven_day_orders", 0)) {
          haveOrders1 = true;
        }
        if (get(data, "data.today_orders", 0)) {
          haveOrders1 = true;
        }
        if (get(data, "data.yesterday_orders", 0)) {
          haveOrders1 = true;
        }
        if (get(data, "data.have_appointments", 0)) {
          haveOrders1 = true;
        }
        // LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
        // this.setState({ haveOrders });
        setHaveOrders(haveOrders1);
      }
    } catch (error) {
      console.log("errrrr", error);
    } finally {
      setLoading(false);
    }
  };

  const getServices = async () => {
    // const {
    //   authActions,
    //   auth: {
    //     auth: { storeInfo },
    //   },
    // } = this.props;
    try {
      const { data } = await client.get(settings.endpoints.getServices);
      if (data.success) {
        let all_services = get(data, "data", []);
        let storeServices = get(storeInfo, "services", null);
        let isIncludes;
        if (storeServices && storeServices.length) {
          for (let e in all_services) {
            if (all_services[e].is_other) {
              isIncludes = storeServices.includes(all_services[e].id);
              if (isIncludes) break;
            }
          }
          // if (isIncludes) {
          //   all_services.push({
          //     name: "Other",
          //     isSelect: true,
          //     image: images.others,
          //   });
          // }
        }
        dispatch(setServices(all_services));
      }
    } catch (error) {
      console.log("services error", error, error.response);
    }
  };

  useEffect(() => {
    getStoreInfo();
    getOfferListing();
    getServices();
    // analyticsEvent('Home', {
    //   type: 'User visits Home Screen',
    // });
    getStatusList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="d-flex flex-center mtop-2 ">
        <div className="w-1000">
          <div className="col-md-12">
            {!storeInfo.is_store_profile_completed && (
              <div className="dashboard-panel mt-4 mb-4">
                <div className="space-between p-2 d-flex">
                  <div className="d-flex">
                    <div>
                      {/* <h1 className="h1 mb-4 p-2 mr-2">Warning!</h1> */}
                      {/* eslint-disable-next-line jsx-a11y/alt-text */}
                      <img src={WarningSign} style={warningStyles} />
                    </div>
                    <div className="ml-5">
                      <p className="font-16 m-0">
                        Your business details are incomplete.
                      </p>
                      <Link to="/business-details">Edit Business Details</Link>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Add Product */}
            {get(storeInfo, "is_store_profile_completed", false) &&
              !storeInfo.is_product_completed && (
                <div className="dashboard-panel mt-4 mb-4">
                  <div className="space-between p-2 d-flex">
                    <div className="d-flex">
                      <div>
                        {/* eslint-disable-next-line jsx-a11y/alt-text */}
                        <img src={WarningSign} style={warningStyles} />
                      </div>
                      <div className="ml-5">
                        <p className="font-16 m-0">
                          To sell on Localhut , Now please add your products, so
                          customers can explore your online store on Localhut
                          app
                        </p>
                        <Link to="/add-products">Add Products</Link>
                      </div>
                    </div>
                  </div>
                </div>
              )}

            <h1 className="h1 mb-8">{`Welcome, ${user?.name}`}</h1>
          </div>
          <div className="col-md-12">
            <div className="d-flex">
              {/* eslint-disable-next-line jsx-a11y/alt-text */}
              <img src={WarningSign} style={warningStyles} />
              <p className="font-17 bold word-break ml-5">
                You are registered as partner now, to proceed further you need
                to provide business details and product information. Follow
                below step to proceed further.
              </p>
            </div>
          </div>
          <div className="dashboard-panel mt-4">
            <div className=" p-2 d-flex space-between">
              <div className="d-flex">
                <div>
                  <h1 className="h1 mb-4 p-2">Step 1</h1>
                </div>
                <div className="ml-5">
                  <p className=" font-16 m-0 pb-3">Download the app now</p>
                  <a
                    href="https://apps.apple.com/gb/app/localhut-partner/id1537423657"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <img src={app_store} alt="App store" height="50px" />
                  </a>

                  <a
                    href="https://play.google.com/store/apps/details?id=com.localhutuk.partner"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <img
                      src={play_store}
                      alt="Play store"
                      height="50px"
                      className="ml-2"
                    />
                  </a>
                </div>
              </div>
              <div className=" d-flex">
                <p className="font-16 bold mr-4">
                  Scan the QR Code to Download app
                </p>
                <div>
                  <img src={QR} alt="qr" width="100px" height="100px" />
                </div>
              </div>
            </div>
          </div>
          <div className="dashboard-panel mt-4">
            <div className="space-between p-2 d-flex">
              <div className="d-flex">
                <div>
                  <h1 className="h1 mb-4 p-2">Step 2</h1>
                </div>
                <div className="ml-5">
                  <p className="font-16 m-0">Log in to app with your</p>
                  <p className="font-16 m-0">{`Email id: ${user?.email}`}</p>
                  <p className="fotn-16 m-0">
                    Password: <span className="grey">XXXXXXXX</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="dashboard-panel mt-4">
            <div className="space-between p-2 d-flex">
              <div className="d-flex">
                <div>
                  <h1 className="h1 mb-4 p-2">Step 3</h1>
                </div>
                <div className="ml-5">
                  <p className="font-16 m-0">
                    Follows the steps in the app to provide business details &
                    product information.
                  </p>
                  <p className="font-16 m-0">
                    If any problem or any question please ask us
                    partner@localhut.co.uk
                  </p>
                  <p className="fotn-16 m-0">Or Call us on +44 751 891 4502</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Dashboard;
