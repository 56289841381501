// import React, { useState } from "react";
// import { useHistory, Link } from "react-router-dom";
// function Password(props) {
//   let history = useHistory();
//   var [mail, setmail] = useState(null);
//   function mailHandler(event) {
//     setmail(event.target.value);
//   }
//   var data = {
//     email: mail,
//   };
//   function OTPcall(event) {
//     event.preventDefault();
//     var myHeaders = new Headers();
//     myHeaders.append("Content-Type", "application/json");
//     myHeaders.append(
//       "Authorization",
//       "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9kZW1vLWh1dC5teWxvY2FsaHV0LmNvbVwvYXBpXC9hZG1pblwvbG9naW4iLCJpYXQiOjE2MTA2NjQ3MDksImV4cCI6MTYxMTUyODcwOSwibmJmIjoxNjEwNjY0NzA5LCJqdGkiOiI0SFI3akhadFBDTnZUUGwyIiwic3ViIjoxLCJwcnYiOiI4N2UwYWYxZWY5ZmQxNTgxMmZkZWM5NzE1M2ExNGUwYjA0NzU0NmFhIiwiaWQiOjEsIm5hbWUiOiJBZG1pbiBBZG1pbiIsInN0b3JlX2lkIjowfQ.bAdEezyhHQHJpSZ-f1iWcZupAhtKZnb940gWIhIwCiY"
//     );
//     // console.log( JSON.stringify(data,null,'\t'));

//     var requestOptions = {
//       method: "POST",
//       headers: myHeaders,
//       body: JSON.stringify(data, null, "\t"),
//       redirect: "follow",
//     };

//     fetch(
//       "http://dev.localhut.co.uk/api/admin/forgot-password?for=otp",
//       requestOptions
//     )
//       .then((response) => response.text())
//       .then((result) => {
//         var extractedResponse = JSON.parse(result);
//         // console.log(extractedResponse);
//         if (extractedResponse.success) {
//           props.response(data);
//           history.push("/verification");
//         }
//         // else if(extractedResponse.hasOwnProperty("error")) {
//         //     alert(extractedResponse.error);
//         // }
//         // else {
//         //     alert("our onboarding team is working on it, We shall get back to you shortly!");
//         // }
//       })
//       .catch((error) => alert(error));
//   }
//   return (
//     <div className="d-flex flex-center center-div">
//       <div className="register">
//         <div className="pad-2">
//           <h1 className="h1">Forgot Password</h1>
//           <form className="needs-validation m-4" onSubmit={OTPcall} noValidate>
//             <p className="lead">Provide your registered email address.</p>
//             <div className="form-row d-flex ">
//               <div className="col-md-12 mb-3">
//                 <input
//                   type="email"
//                   className="form-control login-input"
//                   id="email"
//                   onChange={mailHandler}
//                   name="email"
//                   placeholder="Email Address"
//                   required
//                 />
//                 <div className="invalid-feedback">
//                   Please provide a valid Email.
//                 </div>
//               </div>
//             </div>
//             <button className="btn btn-success btn-block" type="submit">
//               Submit
//             </button>
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// }
// export default Password;

import React, { useState } from "react";
import { useHistory } from "react-router-dom";
// import { ReactComponent as RightArrow } from "../../../images/rightarrow.svg";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import settings from "../../config/settings";

const validationSchema = yup.object({
  email: yup
    .string("Enter email")
    .email("Enter a valid email")
    .required("Email is required"),
});

function Password(props) {
  const history = useHistory();
  const [apiError, setApiError] = useState("");
  const onSubmit = async (values) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9kZW1vLWh1dC5teWxvY2FsaHV0LmNvbVwvYXBpXC9hZG1pblwvbG9naW4iLCJpYXQiOjE2MTA2NjQ3MDksImV4cCI6MTYxMTUyODcwOSwibmJmIjoxNjEwNjY0NzA5LCJqdGkiOiI0SFI3akhadFBDTnZUUGwyIiwic3ViIjoxLCJwcnYiOiI4N2UwYWYxZWY5ZmQxNTgxMmZkZWM5NzE1M2ExNGUwYjA0NzU0NmFhIiwiaWQiOjEsIm5hbWUiOiJBZG1pbiBBZG1pbiIsInN0b3JlX2lkIjowfQ.bAdEezyhHQHJpSZ-f1iWcZupAhtKZnb940gWIhIwCiY"
      );

      var data = {
        email: values.email,
      };

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(data, null, "\t"),
        redirect: "follow",
      };
      setApiError("");
      const URL = `${settings.baseURL}${settings.endpoints.forgotPassword}`;
      const response = await fetch(URL, requestOptions);

      const result = await response.json();
      // var extractedResponse = JSON.parse(result);
      // console.log(extractedResponse);
      if (result.success) {
        props.response(data);
        history.push("/verification");
      } else if (result.success === false) {
        console.log(result);
        setApiError(result.message);
      }
      // else if(result.hasOwnProperty("error")) {
      //   setApiError(result.error);
      // }
      // else {
      //   setApiError("our onboarding team is working on it, We shall get back to you shortly!");
      // }
    } catch (error) {
      setApiError(
        error?.response?.message ||
          "Unexpected Error!. Please try again after some time."
      );
    }
  };
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Card
        style={{
          display: "flex",
          flexDirection: "column",
          width: 453,
          // height: 565,
        }}
      >
        <CardContent
          style={{ flex: 1, display: "flex", flexDirection: "column" }}
        >
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            fontWeight={"700"}
            mb={2}
          >
            Forgot Password
          </Typography>

          <Typography gutterBottom variant="body1" component="p" mb={2}>
            Provide your registered email address{" "}
            {/* <Typography
              style={{ color: theme.palette.primary.main }}
              component="span"
            >
              {props.apiData.email}
            </Typography> */}
          </Typography>

          <TextField
            fullWidth
            id="email"
            name="email"
            label="E-mail"
            variant="standard"
            placeholder="E-mail"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            margin="normal"
          />
          <Typography component={"p"} className="error" variant="caption">
            {apiError}
          </Typography>
        </CardContent>
        <CardActions>
          <Box
            alignItems={"center"}
            justifyContent="center"
            display={"flex"}
            flex={1}
          >
            <Button
              color="primary"
              variant="contained"
              type="submit"
              fullWidth
              // style={{ width: "50%" }}
            >
              Submit
            </Button>
          </Box>
        </CardActions>
      </Card>
    </form>
  );
}
export default Password;
