import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { ReactComponent as RightArrow } from "../../images/rightarrow.svg";
import { LoggedIn, setUserData } from "../../redux/action";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import jwtDecode from "jwt-decode";
import { get } from "lodash";
import settings from "../../config/settings";

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup
    .string("Enter your password")
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
});

const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loginError, setLoginError] = useState("");
  const onSubmit = async (values) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9kZW1vLWh1dC5teWxvY2FsaHV0LmNvbVwvYXBpXC9hZG1pblwvbG9naW4iLCJpYXQiOjE2MTA2NjQ3MDksImV4cCI6MTYxMTUyODcwOSwibmJmIjoxNjEwNjY0NzA5LCJqdGkiOiI0SFI3akhadFBDTnZUUGwyIiwic3ViIjoxLCJwcnYiOiI4N2UwYWYxZWY5ZmQxNTgxMmZkZWM5NzE1M2ExNGUwYjA0NzU0NmFhIiwiaWQiOjEsIm5hbWUiOiJBZG1pbiBBZG1pbiIsInN0b3JlX2lkIjowfQ.bAdEezyhHQHJpSZ-f1iWcZupAhtKZnb940gWIhIwCiY"
      );
      // console.log(JSON.stringify(values, null, "\t"));

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(values, null, "\t"),
        redirect: "follow",
      };
      const URL = `${settings.baseURL}${settings.endpoints.login}`;
      const response = await fetch(URL, requestOptions);
      const result = await response.json();
      setLoginError("");
      const isDevMode = process.env.NODE_ENV === "development";
      if (result.is_approved || isDevMode) {
        const token = get(result, "access_token", "");
        const decoded = jwtDecode(token);
        console.log("result", decoded);
        dispatch(setUserData(token, { ...decoded, email: values.email }));
        history.push("/dashboard");
      } else if (result.hasOwnProperty("error")) {
        setLoginError(result.error);
      } else {
        history.push("/confirmation");
      }
    } catch (error) {
      setLoginError(
        error?.response?.message ||
          "Unexpected Error!. Please try again after some time."
      );
    }
  };
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      showPassword: false,
    },
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  const handleClickShowPassword = () => {
    formik.setValues({
      ...formik.values,
      showPassword: !formik.values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Card style={{ height: 450, display: "flex", flexDirection: "column" }}>
      <CardContent
        style={{ flex: 1, display: "flex", flexDirection: "column" }}
      >
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          fontWeight={"700"}
        >
          Login
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            fullWidth
            id="email"
            name="email"
            label="E-mail"
            variant="standard"
            placeholder="E-mail"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            margin="normal"
          />
          <TextField
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {formik.values.showPassword ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            fullWidth
            id="password"
            name="password"
            label="Password"
            variant="standard"
            type={formik.values.showPassword ? "text" : "password"}
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            margin="normal"
          />
          <Typography component={"p"} className="error" variant="caption">
            {loginError}
          </Typography>
          <Button color="primary" variant="contained" fullWidth type="submit">
            Login
          </Button>
        </form>
      </CardContent>
      <CardActions>
        <Box
          alignItems={"center"}
          justifyContent="flex-end"
          display={"flex"}
          flex={1}
        >
          <Link to="/forgotpassword">
            <Button size="small" variant="text" startIcon={<RightArrow />}>
              Forgot Password?
            </Button>
          </Link>
        </Box>
      </CardActions>
    </Card>
  );
};

export default Login;
