import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import "yup-phone";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import settings from "../../../config/settings";

const validationSchema = yup.object({
  first_name: yup
    .string("Enter your first name")
    .required("First name is required"),
  last_name: yup
    .string("Enter your last name")
    .required("Last name is required"),
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  phone: yup
    .string("Enter your phone")
    .phone("Please enter valid phone")
    .required("Phone is required"),
  country: yup.string("Select your country").required("Country is required"),
  store_name: yup
    .string("Enter your store name")
    .required("Store name is required"),
  isAgree: yup
    .bool("You must agree the terms & conditions and privacy policy")
    .required("You must agree the terms & conditions and privacy policy")
    .isTrue("You must agree the terms & conditions and privacy policy"),
});

function Register(props) {
  const { responseFromRegistration } = props;
  const history = useHistory();
  // const dispatch = useDispatch();
  const [registerError, setRegisterError] = useState("");

  const onSubmit = async (values) => {
    setRegisterError("");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9kZW1vLWh1dC5teWxvY2FsaHV0LmNvbVwvYXBpXC9hZG1pblwvbG9naW4iLCJpYXQiOjE2MTA2NjQ3MDksImV4cCI6MTYxMTUyODcwOSwibmJmIjoxNjEwNjY0NzA5LCJqdGkiOiI0SFI3akhadFBDTnZUUGwyIiwic3ViIjoxLCJwcnYiOiI4N2UwYWYxZWY5ZmQxNTgxMmZkZWM5NzE1M2ExNGUwYjA0NzU0NmFhIiwiaWQiOjEsIm5hbWUiOiJBZG1pbiBBZG1pbiIsInN0b3JlX2lkIjowfQ.bAdEezyhHQHJpSZ-f1iWcZupAhtKZnb940gWIhIwCiY"
    );
    try {
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(values, null, "\t"),
        redirect: "follow",
      };
      const URL = `${settings.baseURL}${settings.endpoints.registration}`;
      const response = await fetch(URL, requestOptions);

      const result = await response.json();

      if (result.success) {
        let responseByApi = {
          email: result.data.email,
        };
        responseFromRegistration(responseByApi);
        history.push("/verify");
      } else {
        setRegisterError(result.message);
      }
    } catch (error) {
      console.log(error);
      setRegisterError(
        error?.response?.message ||
          "Unexpected Error!. Please try again after some time."
      );
    }
  };

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      country: "India",
      store_name: "",
      isAgree: false,
    },
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  return (
    <Card style={{ display: "flex", flexDirection: "column", width: 453 }}>
      <CardContent
        style={{ flex: 1, display: "flex", flexDirection: "column" }}
      >
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          fontWeight={"700"}
        >
          Partner Sign Up
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            fullWidth
            id="first_name"
            name="first_name"
            label="First Name"
            variant="standard"
            placeholder="First Name"
            value={formik.values.first_name}
            onChange={formik.handleChange}
            error={
              formik.touched.first_name && Boolean(formik.errors.first_name)
            }
            helperText={formik.touched.first_name && formik.errors.first_name}
            margin="normal"
          />

          <TextField
            fullWidth
            id="last_name"
            name="last_name"
            label="Last Name"
            variant="standard"
            placeholder="Last Name"
            value={formik.values.last_name}
            onChange={formik.handleChange}
            error={formik.touched.last_name && Boolean(formik.errors.last_name)}
            helperText={formik.touched.last_name && formik.errors.last_name}
            margin="normal"
          />

          <TextField
            fullWidth
            id="email"
            name="email"
            label="E-mail"
            variant="standard"
            placeholder="E-mail"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            margin="normal"
          />

          <TextField
            fullWidth
            id="phone"
            name="phone"
            label="Contact Number"
            variant="standard"
            placeholder="Contact Number"
            value={formik.values.phone}
            onChange={formik.handleChange}
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            helperText={formik.touched.phone && formik.errors.phone}
            margin="normal"
          />

          <TextField
            fullWidth
            id="store_name"
            name="store_name"
            label="Your Business Name"
            variant="standard"
            placeholder="Your Business Name"
            value={formik.values.store_name}
            onChange={formik.handleChange}
            error={
              formik.touched.store_name && Boolean(formik.errors.store_name)
            }
            helperText={formik.touched.store_name && formik.errors.store_name}
            margin="normal"
          />

          <FormControlLabel
            name="isAgree"
            id="isAgree"
            style={{ margin: "25px 0 25px 0" }}
            control={
              <Checkbox
                checked={formik.values.isAgree}
                onChange={formik.handleChange}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label={
              <Typography variant="caption">
                By Continuing,you agree to our{" "}
                <Link href={settings.termsURL} target="_blank" component={"a"}>
                  Terms & Conditions
                </Link>{" "}
                And{" "}
                <Link
                  href={settings.privacyURL}
                  target="_blank"
                  component={"a"}
                >
                  Privacy Policy
                </Link>
              </Typography>
            }
          />

          <Typography component={"p"} className="error" variant="caption">
            {registerError}
          </Typography>
          <Button color="primary" variant="contained" fullWidth type="submit">
            Continue
          </Button>
        </form>
      </CardContent>
    </Card>
  );
}

export default Register;
