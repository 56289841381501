import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0d847c",
    },
    secondary: {
      main: "#1E2838",
    },
    grey: {
      500: "#707070",
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 10,
        },
      },
    },

    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "24px",
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          height: 48,
          margin: "16px 0",
        },
      },
    },
  },
  typography: {
    allVariants: {
      fontFamily: "Rubik, sans-serif",
    },
  },
});

export default theme;
