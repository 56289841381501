import settings from "./settings";

export const category = ["Grocery", "Medicines", "Health"];

const unit = settings.isUK ? "miles" : "Km";

export const DAYS = [
  {
    id: 1,
    name: "Monday",
    label: "Monday",
  },
  {
    id: 2,
    name: "Tuesday",
    label: "Tuesday",
  },
  {
    id: 3,
    name: "Wednesday",
    label: "Wednesday",
  },
  {
    id: 4,
    name: "Thursday",
    label: "Thursday",
  },
  {
    id: 5,
    name: "Friday",
    label: "Friday",
  },
  {
    id: 6,
    name: "Saturday",
    label: "Saturday",
  },
  {
    id: 7,
    name: "Sunday",
    label: "Sunday",
  },
];

export const radius = [
  { label: `1 ${unit}`, value: 1 },
  { label: `2 ${unit}`, value: 2 },
  { label: `3 ${unit}`, value: 3 },
  { label: `4${unit}`, value: 4 },
  { label: `5 ${unit}`, value: 5 },
  { label: `6 ${unit}`, value: 6 },
  { label: `7 ${unit}`, value: 7 },
  { label: `8 ${unit}`, value: 8 },
  { label: `9 ${unit}`, value: 9 },
  { label: `10 ${unit}`, value: 10 },
  { label: `11 ${unit}`, value: 11 },
  { label: `12 ${unit}`, value: 12 },
  { label: `13 ${unit}`, value: 13 },
  { label: `14 ${unit}`, value: 14 },
  { label: `15 ${unit}`, value: 15 },
  { label: `16 ${unit}`, value: 16 },
  { label: `17 ${unit}`, value: 17 },
  { label: `18 ${unit}`, value: 18 },
  { label: `19 ${unit}`, value: 19 },
  { label: `20 ${unit}`, value: 20 },
  { label: `21 ${unit}`, value: 21 },
  { label: `22 ${unit}`, value: 22 },
  { label: `23 ${unit}`, value: 23 },
  { label: `24 ${unit}`, value: 24 },
  { label: `25 ${unit}`, value: 25 },
  { label: `26 ${unit}`, value: 26 },
  { label: `27 ${unit}`, value: 27 },
  { label: `28 ${unit}`, value: 28 },
  { label: `29 ${unit}`, value: 29 },
  { label: `30 ${unit}`, value: 30 },
];

export const radiusUnit = [
  { label: "Miles", value: "miles" },
  { label: "KM", value: "Km" },
];
export const slotGap = [
  { label: "15 mins", value: 15 },
  { label: "30 mins", value: 30 },
  { label: "60 mins", value: 60 },
  { label: "90 mins", value: 90 },
  { label: "120 mins", value: 120 },
];

export const stockFrequencyData = [
  { label: "Daily", value: "1" },
  { label: "Weekly", value: "7" },
  { label: "Monthly", value: "60" },
  { label: "Custom", value: "new" },
];

export const slotIntervalType = ["Fixed", "Range"];

export const futureOrder = [
  { label: "YES", value: "YES" },
  { label: "NO", value: "NO" },
];

export const orderDays = [
  { label: "1 day", value: 1 },
  { label: "2 days", value: 2 },
  { label: "3 days", value: 3 },
  { label: "4 days", value: 4 },
  { label: "5 days", value: 5 },
  { label: "6 days", value: 6 },
  { label: "7 days", value: 7 },
];

export const countries = [
  { label: "India", value: "India" },
  { label: "UK", value: "UK" },
];

export const ordersDate = [
  { label: "Today", value: "today" },
  { label: "Last Week", value: "week" },
  { label: "Last Month", value: "month" },
];

export const setDataArr = (type) => {
  switch (type) {
    case "radius":
      return radius;

    case "radiusUnit":
      return radiusUnit;

    case "slotGap":
      return slotGap;

    case "slotIntervalType":
      return slotIntervalType;

    case "futureOrder":
      return futureOrder;

    case "orderDays":
      return orderDays;

    default:
      break;
  }
};
