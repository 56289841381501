import "./App.css";
import React, { createContext, useState } from "react";
import { useSelector } from "react-redux";
import Container from "@mui/material/Container";
import Verify from "./component/PartnerRegisteration/verification/verify";
import Navbar from "./component/Navigation/navbar";
import Register from "./component/PartnerRegisteration/register/register";
import Dashboard from "./component/Dashboard/dashboard";
import BusinessJourney from "./component/Dashboard/businessJourney/businessJourney";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Congratulations from "./component/PartnerRegisteration/success/congratulatons";
import GoBack from "./component/PartnerRegisteration/goback/goback";
import Login from "./component/login/login";
import ForgotPWD from "./component/login/forgotpwd";
import Successfull from "./component/PartnerRegisteration/success/successfull";
import VerifyForgotPassword from "./component/PartnerRegisteration/verification/verifyforgotpssword";
import GetStarted from "./component/getStarted/getstarted";
import Footer from "./component/Dashboard/Footer/footer";
import Promotion from "./component/Promotion";
import BusinessDetails from "./component/BusinessDetails";
import { Alert, Snackbar } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
// or for moment
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import AddProducts from "./component/Products/AddProducts";

function Routes() {
  const { loggedIn } = useSelector((state) => state.auth);
  const location = useLocation();

  const [data, setData] = useState({
    email: "",
  });

  function mailAllocator(event) {
    setData({ email: event.email });
  }
  return (
    <TransitionGroup className={"main-div-height"}>
      <CSSTransition
        timeout={300}
        classNames="fade"
        key={location?.key || "KEY"}
      >
        <Container
          maxWidth="xl"
          // style={{
          //   display: "flex",
          //   alignItems: "center",
          //   justifyContent: "center",
          //   flexDirection: "column",
          //   minHeight: "calc(100% - 204px)",
          //   paddingLeft: 0,
          //   paddingRight: 0,
          //   width: "100%",
          //   maxWidth: "unset",
          //   paddingTop: 16,
          //   paddingBottom: 16,
          // }}
          className="main-container"
        >
          <Switch location={location}>
            <Route exact path="/">
              {loggedIn ? <Redirect to={"/dashboard"} /> : <GetStarted />}
            </Route>
            <Route path="/dashboard" exact>
              {loggedIn ? <Dashboard /> : <Redirect to={"/"} />}
            </Route>
            <Route path="/register" exact>
              <Register responseFromRegistration={mailAllocator} />
            </Route>
            <Route path="/verify" exact>
              <Verify apiData={data} />
            </Route>
            <Route path="/confirmation" exact component={GoBack} />
            <Route path="/login" exact component={Login} />
            <Route path="/forgotpassword" exact>
              <ForgotPWD response={mailAllocator} />
            </Route>
            <Route path="/verification" exact>
              <VerifyForgotPassword data={data} />
            </Route>
            <Route path="/resetsuccessfull" exact component={Successfull} />
            <Route path="/success" exact component={Congratulations} />
            <Route path="/journey" exact>
              <BusinessJourney />
            </Route>
            <Route path="/promotion" exact>
              <Promotion />
            </Route>
            <Route path="/business-details">
              {loggedIn ? <BusinessDetails /> : <Redirect to={"/"} />}
            </Route>

            <Route path="/add-products">
              {loggedIn ? <AddProducts /> : <Redirect to={"/"} />}
            </Route>
          </Switch>
        </Container>
      </CSSTransition>
    </TransitionGroup>
  );
}

export const SnackbarContext = createContext({});

function App() {
  const [snack, setSnack] = useState({ open: false });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnack({ color: snack.color, open: false });
  };

  const showSuccessToast = (message) => {
    setSnack({ message, color: "success", autoHideDuration: 1500, open: true });
  };

  const showErrorToast = (message) => {
    setSnack({ message, color: "error", autoHideDuration: 1500, open: true });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <SnackbarContext.Provider
        value={{ snack, setSnack, showSuccessToast, showErrorToast }}
      >
        <Snackbar
          open={snack.open}
          autoHideDuration={snack.autoHideDuration}
          onClose={handleClose}
        >
          <Alert severity={snack.color}>{snack.message || ""}</Alert>
        </Snackbar>
        <Router>
          <Navbar />
          <Routes />
          <Footer />
        </Router>
      </SnackbarContext.Provider>
    </LocalizationProvider>
  );
}
export default App;
