import React, { useContext, useRef, useState } from "react";
import _, { get } from "lodash";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Slide from "@mui/material/Slide";
import Edit from "@mui/icons-material/Edit";
import Delete from "@mui/icons-material/Delete";
import DeliveryOptionForm from "./DeliveryOptionForm";

import LoadingButton from "@mui/lab/LoadingButton";

import { client } from "../../common";
import settings from "../../config/settings";

import { SnackbarContext } from "../../App";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DeliveryOption({
  item,
  index,
  onDeleteSuccess,
  onUpdateSuccess,
}) {
  const { showSuccessToast, showErrorToast } = useContext(SnackbarContext);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isDeleting, setDeleting] = useState(false);
  const formRef = useRef(null);
  const detail = useSelector((state) => state.auth.bBaicInfo);

  const openEditForm = () => {
    if (formRef?.current) {
      formRef?.current.openForm();
    }
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const removePrice = async (i) => {
    try {
      const endPoint = settings.endpoints.updateDeliveryOption(item?.id);
      setDeleting(true);
      const response = await client.delete(endPoint);
      //   console.log("Response of delete price------", response);
      if (response.data.success) {
        showSuccessToast(get(response, "data.message", ""));
        onDeleteSuccess();
      } else {
        showErrorToast(get(response, "data.message", "Server Error!!"));
      }
      setDeleting(false);
    } catch (error) {
      setDeleting(false);
      showErrorToast(get(error, "response.message", "Server Error!!"));
    }
  };

  return (
    <>
      <Box
        onPress={handleDialogOpen}
        borderColor="#CCC"
        borderStyle="solid"
        borderWidth={1}
        display={"flex"}
        flexDirection="row"
        mx={2}
        px={2}
        my={2}
        style={{
          // backgroundColor: "#e5e5e5",
          borderColor: "#e5e5e5",
          borderWidth: 1,
          borderRadius: 5,
          borderStyle: "solid",
          width: "100%",
        }}
      >
        <Box
          display={"flex"}
          flexDirection="column"
          flex={1}
          alignItems="flex-start"
          justifyContent={"center"}
        >
          <Box display={"flex"} flexDirection="row" my={1}>
            <Typography mr={1} fontWeight={"700"}>
              {"Option Name: "}
            </Typography>
            <Typography>{item?.name || ""}</Typography>
          </Box>

          <Box display={"flex"} flexDirection="row" my={1}>
            <Typography mr={1} fontWeight={"700"}>
              {"Description: "}
            </Typography>
            <Typography>{item?.description || ""}</Typography>
          </Box>

          <Box display={"flex"} flexDirection="row" my={1}>
            <Typography mr={1} fontWeight={"700"}>
              {"Delivery Price: "}
            </Typography>
            <Typography>{`${detail?.currency_symbol || ""} ${
              item?.price
            }`}</Typography>
          </Box>
        </Box>
        <Box
          display={"flex"}
          flexDirection="row"
          alignItems={"center"}
          justifyContent="flex-end"
        >
          <LoadingButton
            size="small"
            style={{ padding: 0 }}
            onClick={openEditForm}
          >
            <Edit />
          </LoadingButton>
          <LoadingButton
            size="small"
            style={{ padding: 0 }}
            onClick={handleDialogOpen}
            disabled={isDeleting}
            loading={isDeleting}
            loadingIndicator="Loading…"
          >
            <Delete />
          </LoadingButton>
        </Box>
      </Box>
      <Dialog
        open={dialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleDialogClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Delete Delivery Option"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete this delivery option?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={removePrice}>Delete</Button>
        </DialogActions>
      </Dialog>
      <DeliveryOptionForm
        ref={formRef}
        item={item}
        index={index}
        onSuccess={onUpdateSuccess}
      />
    </>
  );
}
