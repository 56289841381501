import React, { useMemo, useState } from "react";
import _, { get } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { isArray } from "lodash";
import { useFormik } from "formik";
import * as yup from "yup";
import "yup-phone";
import moment from "moment";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";

import settings from "../../config/settings";
import { setBuisnessBasicInfo } from "../../redux/action";
import { client } from "../../common";
import { DAYS } from "../../config/staticData";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { FormHelperText } from "@mui/material";

const validationSchema = yup.object({
  data: yup.object().shape({
    Monday: yup.array().required(),
    Tuesday: yup.array().required(),
    Wednesday: yup.array().required(),
    Thursday: yup.array().required(),
    Friday: yup.array().required(),
    Saturday: yup.array().required(),
    Sunday: yup.array().required(),
  }),
});

function TimePickerField({
  name,
  value,
  onChange,
  label,
  error,
  helperText,
  ...rest
}) {
  return (
    <TimePicker
      label={label}
      value={value}
      ampm={false}
      minutesStep={15}
      inputFormat="HH:mm"
      onChange={onChange}
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          error={error}
          helperText={helperText}
        />
      )}
      {...rest}
    />
  );
}

// function WorkingDayItem({
//   isChecked,
//   day,
//   handleDayCheckbox,
//   data,
//   handleChangeTime,
// }) {
//   const dayData = useMemo(() => {
//     const ret = {
//       delivery: {
//         startTime: "9:00",
//         closeTime: "18:00",
//         type: "1",
//         status: true,
//       },
//       collection: {
//         startTime: "9:00",
//         closeTime: "18:00",
//         type: "0",
//         status: true,
//       },
//     };
//     const key = day.name;
//     const dData = data[key];
//     if (dData === "Monday") {
//       console.log("dData", dData);
//     }

//     if (dData) {
//       // eslint-disable-next-line eqeqeq
//       let delivery = _.find(dData, (i) => i.type == 1);
//       // eslint-disable-next-line eqeqeq
//       let collection = _.find(dData, (i) => i.type == 1);
//       if (delivery) {
//         ret.delivery = delivery;
//       }

//       if (collection) {
//         ret.collection = collection;
//       }
//     }
//     return ret;
//   }, [data, day.name]);

//   const { delivery, collection } = dayData;
//   const onChangeTime = useCallback(
//     (time, type, key) => {
//       handleChangeTime(day.name, {
//         [key]: time.format("HH:mm"),
//         type,
//       });
//     },
//     [day.name, handleChangeTime]
//   );

//   return (
//     <>
//       <FormControlLabel
//         control={
//           <Checkbox
//             checked={isChecked}
//             onChange={handleDayCheckbox}
//             name={day.name}
//           />
//         }
//         label={day.label}
//       />

//       {isChecked && (
//         <Box
//           display={"flex"}
//           flexDirection="column"
//           style={{ width: "100%", minHeight: 200 }}
//         >
//           <Box
//             display={"flex"}
//             flexDirection="row"
//             alignItems={"center"}
//             justifyContent="center"
//             my={1}
//           >
//             <Box
//               display={"flex"}
//               flex={1}
//               justifyContent={"flex-start"}
//               alignItems="center"
//               mx={1}
//             >
//               <Typography mr={1}>Delivery</Typography>
//             </Box>

//             <Box display={"flex"} flex={1} mx={1}>
//               <TimePicker
//                 label="Start Time"
//                 value={moment(delivery.startTime, "HH:mm")}
//                 ampm={false}
//                 minutesStep={15}
//                 inputFormat="HH:mm"
//                 onChange={(time) => onChangeTime(time, "1", "startTime")}
//                 renderInput={(params) => <TextField {...params} />}
//               />
//             </Box>

//             <Box display={"flex"} flex={1} mx={1}>
//               <TimePicker
//                 label="Close Time"
//                 ampm={false}
//                 minutesStep={15}
//                 value={moment(delivery.closeTime, "HH:mm")}
//                 onChange={(time) => onChangeTime(time, "1", "closeTime")}
//                 renderInput={(params) => <TextField {...params} />}
//               />
//             </Box>
//           </Box>

//           <Box
//             display={"flex"}
//             flexDirection="row"
//             alignItems={"center"}
//             justifyContent="center"
//             my={1}
//           >
//             <Box
//               display={"flex"}
//               flex={1}
//               justifyContent={"flex-start"}
//               alignItems="center"
//               mx={1}
//             >
//               <Typography mr={1}>Collection</Typography>
//             </Box>

//             <Box display={"flex"} flex={1} mx={1}>
//               <TimePicker
//                 label="Start Time"
//                 ampm={false}
//                 minutesStep={15}
//                 value={moment(collection.startTime, "HH:mm")}
//                 onChange={(time) => onChangeTime(time, "0", "startTime")}
//                 renderInput={(params) => <TextField {...params} />}
//               />
//             </Box>

//             <Box display={"flex"} flex={1} mx={1}>
//               <TimePicker
//                 label="Close Time"
//                 ampm={false}
//                 minutesStep={15}
//                 value={moment(collection.closeTime, "HH:mm")}
//                 onChange={(time) => onChangeTime(time, "0", "closeTime")}
//                 renderInput={(params) => <TextField {...params} />}
//               />
//             </Box>
//           </Box>
//         </Box>
//       )}
//     </>
//   );
// }

export default function WorkingHours() {
  // const { showSuccessToast, showErrorToast } = useContext(SnackbarContext);
  const dispatch = useDispatch();
  const history = useHistory();
  const userData = useSelector((state) => state.auth.userdata);
  // const detail = useSelector((state) => state.auth.bBaicInfo);

  const isEdit = false;
  const storeID = useMemo(() => get(userData, "store_id", 0), [userData]);

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (values) => {
    console.log("values >>", values);
    let isLength = Object.values(values.data).filter((e) => e.length);

    if (!isLength.length) {
      setError("Please select al-least one day and time");
      return;
    }

    const reqData = {
      ...values.data,
      store_id: storeID,
    };

    try {
      setIsLoading(true);
      const { data } = await client.post(
        settings.endpoints.workingSlots,
        reqData
      );
      if (data.success) {
        // navigation.navigate('BuisnessPaymentInfo');
        if (isEdit) {
          history.push(`/business-details/confirmation`);
        } else {
          history.push(`/business-details/payment-info`);
        }
      }
      // analyticsEvent('Update_Business_Details', {
      //   type: 'User update Business Working Hours',
      // });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("errror>>>>>.", error, error.response);
    }
  };

  const formik = useFormik({
    initialValues: {
      data: {
        Monday: [
          {
            startTime: "9:00",
            closeTime: "18:00",
            type: "1",
            status: true,
            // isChecked: true,
          },
          {
            startTime: "9:00",
            closeTime: "18:00",
            type: "0",
            status: true,
            // isChecked: true,
          },
        ],
        Tuesday: [],
        Wednesday: [],
        Thursday: [],
        Friday: [],
        Saturday: [],
        Sunday: [],
      },
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  const { touched, values, errors, setFieldValue, handleSubmit } = formik;

  const isChecked = (key) =>
    values?.data && isArray(values?.data?.[key])
      ? values?.data?.[key].length > 0
      : false;

  // const handleChangeTime = (dayKey, obj) => {
  //   console.log("dayKey, obj", dayKey, obj);
  //   let nData = { ...values.data };
  //   // const key = e.target.name;

  //   if (nData[dayKey]) {
  //     if (isArray(nData[dayKey]) && nData[dayKey].length > 0) {
  //       if (obj.type == 1 && nData[dayKey][0]) {
  //         nData[dayKey][0] = {
  //           ...nData[dayKey][0],
  //           ...obj,
  //         };
  //       }

  //       if (obj.type == 0 && nData[dayKey][1]) {
  //         nData[dayKey][1] = {
  //           ...nData[dayKey][1],
  //           ...obj,
  //         };
  //       }
  //     }
  //   } else {
  //     nData[dayKey] = [];
  //   }
  //   console.log("nData", nData);
  //   setFieldValue("data", nData, true);
  // };

  const handleDayCheckbox = (e) => {
    let nData = { ...values.data };
    const key = e.target.name;

    if (!isChecked(key)) {
      nData[key] = [
        {
          startTime: "9:00",
          closeTime: "18:00",
          type: "1",
          status: true,
        },
        {
          startTime: "9:00",
          closeTime: "18:00",
          type: "0",
          status: true,
        },
      ];
    } else {
      nData[key] = [];
    }
    setFieldValue("data", nData, true);
  };

  return (
    <Card style={{ display: "flex", flexDirection: "column", width: 453 }}>
      <CardContent
        style={{ flex: 1, display: "flex", flexDirection: "column" }}
      >
        <Typography variant="h5" component="div" fontWeight={"700"}>
          Business hours
        </Typography>
        <Typography>Select your weekly business hours</Typography>
        <form onSubmit={handleSubmit}>
          <Box flexDirection="column" display={"flex"} my={2}>
            <FormControl
              required
              error={touched.data && errors.data}
              component="fieldset"
              sx={{ my: 1 }}
              variant="standard"
            >
              <FormLabel component="legend">
                Add delivery and collection hours
              </FormLabel>
              <FormGroup>
                {DAYS.map((day, index) => {
                  return (
                    <React.Fragment
                      key={`day_working_hours_section_${day.name}_${index}`}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isChecked(day.name)}
                            onChange={handleDayCheckbox}
                            name={day.name}
                            key={`day_checkbox_${day.name}_${index}`}
                          />
                        }
                        key={`day_checkbox_form_control_${day.name}_${index}`}
                        label={day.label}
                      />

                      {isChecked(day.name) && (
                        <Box
                          key={`timing_section_${day.name}_${index}`}
                          display={"flex"}
                          flexDirection="column"
                          style={{ width: "100%" }}
                        >
                          <Box
                            display={"flex"}
                            flexDirection="row"
                            alignItems={"center"}
                            justifyContent="center"
                            my={1}
                          >
                            <Box
                              display={"flex"}
                              flex={1}
                              justifyContent={"flex-start"}
                              alignItems="center"
                              mx={1}
                            >
                              <Typography mr={1}>Delivery</Typography>
                            </Box>

                            <Box display={"flex"} flex={1} mx={1}>
                              <TimePickerField
                                key={`delivery_start_time_${day.name}_${index}`}
                                label="Start Time"
                                value={moment(
                                  formik.values.data[day.name][0].startTime,
                                  "HH:mm"
                                )}
                                maxTime={moment(
                                  formik.values.data[day.name][0].closeTime,
                                  "HH:mm"
                                )}
                                name={`data[${day.name}][0].startTime`}
                                error={
                                  touched[`data[${day.name}][0].startTime`] &&
                                  Boolean(
                                    errors[`data[${day.name}][0].startTime`]
                                  )
                                }
                                helperText={
                                  touched[`data[${day.name}][0].startTime`] &&
                                  errors[`data[${day.name}][0].startTime`]
                                }
                                onChange={(time) => {
                                  setFieldValue(
                                    `data[${day.name}][0].startTime`,
                                    time.format("HH:mm"),
                                    true
                                  );
                                }}
                              />
                            </Box>

                            <Box display={"flex"} flex={1} mx={1}>
                              <TimePickerField
                                key={`delivery_close_time_${day.name}_${index}`}
                                label="Close Time"
                                value={moment(
                                  formik.values.data[day.name][0].closeTime,
                                  "HH:mm"
                                )}
                                minTime={moment(
                                  formik.values.data[day.name][0].startTime,
                                  "HH:mm"
                                )}
                                name={`data[${day.name}][0].closeTime`}
                                error={
                                  touched[`data[${day.name}][0].closeTime`] &&
                                  Boolean(
                                    errors[`data[${day.name}][0].closeTime`]
                                  )
                                }
                                helperText={
                                  touched[`data[${day.name}][0].closeTime`] &&
                                  errors[`data[${day.name}][0].closeTime`]
                                }
                                onChange={(time) => {
                                  setFieldValue(
                                    `data[${day.name}][0].closeTime`,
                                    time.format("HH:mm"),
                                    true
                                  );
                                }}
                              />
                            </Box>
                          </Box>

                          <Box
                            display={"flex"}
                            flexDirection="row"
                            alignItems={"center"}
                            justifyContent="center"
                            my={1}
                          >
                            <Box
                              display={"flex"}
                              flex={1}
                              justifyContent={"flex-start"}
                              alignItems="center"
                              mx={1}
                            >
                              <Typography mr={1}>Collection</Typography>
                            </Box>

                            <Box display={"flex"} flex={1} mx={1}>
                              <TimePickerField
                                key={`collection_start_time_${day.name}_${index}`}
                                label="Start Time"
                                name={`data[${day.name}][1].startTime`}
                                value={moment(
                                  formik.values.data[day.name][1].startTime,
                                  "HH:mm"
                                )}
                                maxTime={moment(
                                  formik.values.data[day.name][1].closeTime,
                                  "HH:mm"
                                )}
                                error={
                                  touched[`data[${day.name}][1].startTime`] &&
                                  Boolean(
                                    errors[`data[${day.name}][1].startTime`]
                                  )
                                }
                                helperText={
                                  touched[`data[${day.name}][1].startTime`] &&
                                  errors[`data[${day.name}][1].startTime`]
                                }
                                onChange={(time) => {
                                  setFieldValue(
                                    `data[${day.name}][1].startTime`,
                                    time.format("HH:mm"),
                                    true
                                  );
                                }}
                              />
                            </Box>

                            <Box display={"flex"} flex={1} mx={1}>
                              <TimePickerField
                                key={`collection_close_time_${day.name}_${index}`}
                                label="Close Time"
                                name={`data[${day.name}][1].closeTime`}
                                value={moment(
                                  formik.values.data[day.name][1].closeTime,
                                  "HH:mm"
                                )}
                                minTime={moment(
                                  formik.values.data[day.name][1].startTime,
                                  "HH:mm"
                                )}
                                error={
                                  touched[`data[${day.name}][1].closeTime`] &&
                                  Boolean(
                                    errors[`data[${day.name}][1].closeTime`]
                                  )
                                }
                                helperText={
                                  touched[`data[${day.name}][1].closeTime`] &&
                                  errors[`data[${day.name}][1].closeTime`]
                                }
                                onChange={(time) => {
                                  setFieldValue(
                                    `data[${day.name}][1].closeTime`,
                                    time.format("HH:mm"),
                                    true
                                  );
                                }}
                              />
                            </Box>
                          </Box>
                        </Box>
                      )}
                    </React.Fragment>
                  );
                })}
              </FormGroup>
              {touched.data && errors.data && (
                <FormHelperText
                  component={"p"}
                  className="error"
                  variant="caption"
                >
                  {errors.data}
                </FormHelperText>
              )}
            </FormControl>
          </Box>

          {error && (
            <Typography component={"p"} className="error" variant="caption">
              {error}
            </Typography>
          )}
          <LoadingButton
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            disabled={isLoading}
            loading={isLoading}
            loadingIndicator="Loading…"
          >
            {isEdit ? "Save" : "Continue"}
          </LoadingButton>
        </form>
      </CardContent>
    </Card>
  );
}
