import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import { useHistory } from "react-router-dom";
import {
  Card,
  CardContent,
  Typography,
  FormControl,
  FormControlLabel,
  FormLabel,
  Checkbox,
  FormHelperText,
  FormGroup,
  Box,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useFormik } from "formik";
import * as yup from "yup";
import "yup-phone";

// import { SnackbarContext } from "../../App";
import settings from "../../config/settings";
import { setBuisnessBasicInfo } from "../../redux/action";
import { client } from "../../common";

const validationSchema = yup.object({
  is_payment_cod: yup.bool().required(),
  is_payment_card: yup.bool().required(),
  is_digital_payments: yup.bool().required(),
});

export default function BuisnessPaymentInfo() {
  // const { showSuccessToast, showErrorToast } = useContext(SnackbarContext);
  const dispatch = useDispatch();
  const history = useHistory();
  const userData = useSelector((state) => state.auth.userdata);
  const detail = useSelector((state) => state.auth.bBaicInfo);

  const isEdit = false;

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (values) => {
    console.log(values);
    setIsLoading(true);
    setError(null);
    const obj = {
      is_payment_cod: values.is_payment_cod ? 1 : 0,
      is_payment_card: values.is_payment_card ? 1 : 0,
      is_digital_payments: values.is_digital_payments,
    };

    try {
      const { data } = await client.put(
        settings.endpoints.updateStore(userData.store_id),
        obj
      );
      setIsLoading(false);
      if (data.success) {
        dispatch(setBuisnessBasicInfo(data.data));
        // if (isEdit) {
        history.push(`/business-details/confirmation`);
        // }
        // navigation.navigate('BuisnessConfirmation');
        // analyticsEvent('Update_Business_Details', {
        //   type: 'User updated Payment Info Details',
        // });
      }
    } catch (error) {
      setIsLoading(false);
      setError(
        error?.response?.message ||
          "Server Error!. Please try again after some time."
      );
      console.log("error>>>", error, error.response);
    }
  };

  const formik = useFormik({
    initialValues: {
      is_payment_cod: get(detail, "is_payment_cod") == 1 ? true : false,
      is_payment_card: get(detail, "is_payment_card") == 1 ? true : false,
      is_digital_payments: get(detail, "is_digital_payments", false),
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  const {
    touched,
    values,
    errors,
    // handleChange,
    // handleReset,
    // handleSubmit,
    setFieldValue,
  } = formik;
  const handleCheckbox = (e) => {
    const key = e.target.name;
    setFieldValue(key, !values[key], true);
  };

  const checkError = (key) => {
    return touched[key] && Boolean(errors[key]);
  };

  const isError =
    checkError("is_payment_cod") ||
    checkError("is_payment_card") ||
    checkError("is_digital_payments");
  return (
    <Card style={{ display: "flex", flexDirection: "column", width: 453 }}>
      <CardContent
        style={{ flex: 1, display: "flex", flexDirection: "column" }}
      >
        <Typography
          // gutterBottom
          variant="h5"
          component="div"
          fontWeight={"700"}
        >
          Payment Information
        </Typography>
        <Typography>How would you like to receive payments?</Typography>
        <form onSubmit={formik.handleSubmit}>
          <Box flexDirection="column" display={"flex"} my={2}>
            <FormControl
              required
              error={isError}
              component="fieldset"
              sx={{ my: 1 }}
              variant="standard"
            >
              <FormLabel component="legend">Select payment options</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.is_payment_cod}
                      onChange={handleCheckbox}
                      name="is_payment_cod"
                    />
                  }
                  label="Cash on delivery"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.is_payment_card}
                      onChange={handleCheckbox}
                      name="is_payment_card"
                    />
                  }
                  label="Card payments"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.is_digital_payments}
                      onChange={handleCheckbox}
                      name="is_digital_payments"
                    />
                  }
                  label="Digital payments (Apple Pay/GPay)"
                />
              </FormGroup>
              {checkError("is_payment_cod") ? (
                <FormHelperText
                  component={"p"}
                  className="error"
                  variant="caption"
                >
                  {errors.is_payment_cod}
                </FormHelperText>
              ) : null}
              {checkError("is_payment_card") ? (
                <FormHelperText
                  component={"p"}
                  className="error"
                  variant="caption"
                >
                  {errors.is_payment_card}
                </FormHelperText>
              ) : null}
              {checkError("is_digital_payments") ? (
                <FormHelperText
                  component={"p"}
                  className="error"
                  variant="caption"
                >
                  {errors.is_digital_payments}
                </FormHelperText>
              ) : null}
            </FormControl>
          </Box>
          {/* <FormControl
            required
            error={touched.active && Boolean(errors.active)}
            component="fieldset"
            sx={{ my: 1 }}
            variant="standard"
          >
            <FormLabel component="legend">Select payment options</FormLabel>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.active}
                  onChange={handleCheckbox}
                  name="active"
                />
              }
              label="Cash on delivery"
            />

            {touched.active && errors.active ? (
              <FormHelperText
                component={"p"}
                className="error"
                variant="caption"
              >
                {errors.active}
              </FormHelperText>
            ) : null}
          </FormControl> */}
          {error && (
            <Typography component={"p"} className="error" variant="caption">
              {error}
            </Typography>
          )}
          <LoadingButton
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            disabled={isLoading}
            loading={isLoading}
            loadingIndicator="Loading…"
          >
            {isEdit ? "Save" : "Continue"}
          </LoadingButton>
        </form>
      </CardContent>
    </Card>
  );
}
