import {
  ADD_CATEGORY,
  ADD_DELIVERY_PRICE,
  ADD_PRODUCT,
  ADD_PRODUCT_CATEGORY,
  DELETE_CATEGORY,
  DELETE_DELIVERY_PRICE,
  DELETE_PRODUCT,
  LOGOUT,
  REMOVE_PRODUCT_IMAGE,
  SET_BUISNESS_BASICINFO,
  SET_BUISNESS_SERVICES,
  SET_CATEGORY_LIST,
  SET_DATA,
  SET_DELIVERY_OPTIONS,
  SET_DELIVERY_PRICES,
  SET_PRODUCT_LIST,
  SET_SCREEN,
  SET_STAFF_LIST,
  SET_STATUS_LIST,
  SET_USER,
  SET_USER_ROLES,
  STORE_INFO,
  UPDATE_CATEGORY,
  UPDATE_DELIVERY_OPTION,
  UPDATE_DELIVERY_PRICE,
  UPDATE_PRODUCT,
} from "./types";

export function setUserData(token, data) {
  let tok = "";
  let udata = {};
  if (token !== undefined && token !== null && token !== "") {
    tok = token;
  }
  if (data !== undefined && data !== null && Object.keys(data).length > 0) {
    udata = data;
  }
  return {
    type: SET_DATA,
    token: tok,
    userdata: udata,
    loggedIn: token !== "",
  };
}

export function setUser(data) {
  return {
    type: SET_USER,
    userdata: data,
  };
}

export function logout() {
  return {
    type: LOGOUT,
  };
}

export function setProductList(data) {
  return {
    type: SET_PRODUCT_LIST,
    data,
  };
}

export function addProduct(data) {
  return {
    type: ADD_PRODUCT,
    data,
  };
}

export function addProductCategory(data) {
  return {
    type: ADD_PRODUCT_CATEGORY,
    data,
  };
}

export function updateProduct(data) {
  return {
    type: UPDATE_PRODUCT,
    data,
  };
}

export function deleteProduct(data) {
  return {
    type: DELETE_PRODUCT,
    data,
  };
}

export function removeProductImage(data) {
  return {
    type: REMOVE_PRODUCT_IMAGE,
    data,
  };
}

export function addCategory(data) {
  return {
    type: ADD_CATEGORY,
    data,
  };
}
export function updateCategory(data) {
  return {
    type: UPDATE_CATEGORY,
    data,
  };
}
export function deleteCategory(data) {
  return {
    type: DELETE_CATEGORY,
    data,
  };
}

export function setScreen(screen) {
  return {
    type: SET_SCREEN,
    screen,
  };
}

export function setStatusList(data) {
  return {
    type: SET_STATUS_LIST,
    statusList: data,
  };
}

export function setBuisnessBasicInfo(data) {
  return {
    type: SET_BUISNESS_BASICINFO,
    data,
  };
}

export function setCategories(data) {
  return {
    type: SET_CATEGORY_LIST,
    data,
  };
}

export function setStoreInfo(data) {
  return {
    type: STORE_INFO,
    data,
  };
}

export function setServices(data) {
  return {
    type: SET_BUISNESS_SERVICES,
    data,
  };
}

export function setStaffList(data) {
  return {
    type: SET_STAFF_LIST,
    data,
  };
}

export function setUserRoles(data) {
  return {
    type: SET_USER_ROLES,
    data,
  };
}

export function setDeliveryPrices(data) {
  return {
    type: SET_DELIVERY_PRICES,
    data,
  };
}

export function setDeliveryOptions(data) {
  return {
    type: SET_DELIVERY_OPTIONS,
    data,
  };
}

export function addDeliveryPrice(data) {
  return {
    type: ADD_DELIVERY_PRICE,
    data,
  };
}

export function updateDeliveryPrice(data) {
  return {
    type: UPDATE_DELIVERY_PRICE,
    data,
  };
}

export function updateDeliveryOption(data) {
  return {
    type: UPDATE_DELIVERY_OPTION,
    data,
  };
}

export function deleteDeliveryPrice(data) {
  return {
    type: DELETE_DELIVERY_PRICE,
    data,
  };
}
