import React from "react";
import "./footer.css";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import { useMemo } from "react";
import settings from "../../../config/settings";

const Footer = () => {
  const year = useMemo(() => new Date().getFullYear(), []);
  return (
    <AppBar
      // position="fixed"
      position="relative"
      color="primary"
      //
      sx={{ top: "auto", bottom: 0, backgroundColor: "#1E2838" }}
      className="copyright"
    >
      <Toolbar
        className="copyright-menu"
        style={{ alignItems: "center", justifyContent: "center" }}
      >
        <Box
          maxWidth={"lg"}
          display={"flex"}
          flexDirection={"row"}
          alignItems="center"
          flex={1}
          justifyContent={"space-between"}
        >
          <Box>
            <Link href={settings.termsURL} target="_blank" component={"a"}>
              Terms & Conditions
            </Link>
            <Link
              href={settings.privacyURL}
              target="_blank"
              className="ml-4"
              component={"a"}
            >
              Privacy Policy
            </Link>
          </Box>

          <Box>
            <Typography variant="body2" color="white">
              {`Copyright © ${year}, Localhut`}
            </Typography>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Footer;
