import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import { Box, Card, CardContent, TextField, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useFormik } from "formik";
import * as yup from "yup";
import "yup-phone";
import { useHistory } from "react-router-dom";
import settings from "../../config/settings";
import { client } from "../../common";
import { setBuisnessBasicInfo, setServices } from "../../redux/action";
import FileUpload from "react-material-file-upload";
import { SnackbarContext } from "../../App";

const validationSchema = yup.object({
  // images: yup
  //   .array("Please select store image")
  //   .optional()
  //   // .required("Please select store image")
  //   .min(1, "Please select store image"),
  name: yup
    .string("Enter your business name")
    .required("Business name is required"),
  description: yup
    .string("Enter description")
    .required("Description is required"),
});

export default function BuisnessBasicDetails() {
  const { showSuccessToast, showErrorToast } = useContext(SnackbarContext);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth.userdata);
  const detail = useSelector((state) => state.auth.bBaicInfo);

  const isEdit = false;

  const history = useHistory();
  const [images, setImages] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);

  const getBusinnessServices = async () => {
    try {
      const { data } = await client.get(settings.endpoints.getServices);
      if (data.success) {
        dispatch(setServices(data.data));
      }
    } catch (error) {
      console.log("services error", error, error.response);
    }
  };

  const onSubmit = async (values) => {
    let obj = {
      name: values.name,
      description: values.description,
      country: settings.isUK ? "UK" : "India",
      currency_symbol: settings.isUK ? "£" : "₹",
      currency_code: settings.isUK ? "GBP" : "INR",
      delivery_radius_unit: settings.isUK ? "miles" : "Km",
      service_charge: Number(get(detail, "service_charge", 0)),
      delivery_time: Number(get(detail, "delivery_time", 0)),
      last_order_cutt_off: Number(get(detail, "last_order_cutt_off", 0)),
    };
    setIsLoading(true);
    setError(null);
    try {
      const { data } = await client.put(
        settings.endpoints.updateStore(userData.store_id),
        obj
      );
      setIsLoading(false);
      setError(null);
      if (data.success) {
        dispatch(setBuisnessBasicInfo(data.data));
        if (isEdit) {
          history.push(`/business-details/confirmation`);
        } else {
          history.push(`/business-details/address-details`);
        }
        // analyticsEvent("Update_Business_Details", {
        //   type: "User updated Store Basic Details",
        // });
        await getBusinnessServices();
        // setState((s) => ({ ...s, other_service: "" }));
      }
      console.log("Response of updated basic details----", data);
    } catch (error) {
      setIsLoading(false);
      setError(
        error?.response?.message ||
          "Server Error!. Please try again after some time."
      );
      console.log("error >>>", error, error.response);
    }
  };

  const pickImage = (files) => {
    // console.log("files", files);
    if (files && files[0]) {
      const fileObj = files[0];
      // formik.setFieldValue("images", files, true);
      setImages(files);
      let formData = new FormData();
      formData.append("image", fileObj);
      updateStoreImage(formData);
    }
  };

  // const showSuccessToast = (message) => {
  //   setSnack({ message, color: "success", autoHideDuration: 1500, open: true });
  // };

  // const showErrorToast = (message) => {
  //   setSnack({ message, color: "error", autoHideDuration: 1500, open: true });
  // };

  const updateStoreImage = async (image_data) => {
    try {
      setImageLoading(true);
      const endPoint = settings.endpoints.updateStore(userData.store_id);
      const { data } = await client.post(endPoint, image_data);
      setImageLoading(false);
      if (data.success) {
        showSuccessToast("Store Image Updated Successfully");
        dispatch(setBuisnessBasicInfo(data.data));
        // analyticsEvent('Update_Business_Details', {
        //   type: 'User updated Store Image',
        // });
      } else {
        showErrorToast(data?.message || "Server Error!");
      }
      // console.log('Response of updated store image----', data);
    } catch (error) {
      setImageLoading(false);
      showErrorToast(error?.response?.message || "Server Error!");
      // console.log("error >>>", error, error.response);
    }
  };

  const formik = useFormik({
    initialValues: {
      // images: [],
      name: detail?.name || "",
      description: detail?.description || "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  return (
    <Card style={{ display: "flex", flexDirection: "column", width: 453 }}>
      <CardContent
        style={{ flex: 1, display: "flex", flexDirection: "column" }}
      >
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          fontWeight={"700"}
        >
          Add Addresss
        </Typography>
        <Typography gutterBottom>Provide store address</Typography>
        <form onSubmit={formik.handleSubmit}>
          <Box my={3}>
            <Typography mb={2}>Store Logo</Typography>
            <FileUpload
              accept={["image/*"]}
              disabled={imageLoading}
              maxSize={10 * 1000000}
              value={images}
              multiple={false}
              onChange={pickImage}
            />
          </Box>
          {formik.touched.images && Boolean(formik.errors.images) ? (
            <Typography component={"p"} className="error" variant="caption">
              {formik.errors.images}
            </Typography>
          ) : null}
          <TextField
            fullWidth
            id="name"
            name="name"
            label="Business Name"
            variant="standard"
            placeholder="Business Name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            margin="normal"
          />

          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          ></Box>
          <TextField
            fullWidth
            id="description"
            name="description"
            label="Description"
            variant="standard"
            multiline
            InputProps={{
              multiline: true,
              style: { height: 200, textAlign: "start" },
            }}
            placeholder="Description"
            value={formik.values.description}
            onChange={formik.handleChange}
            error={
              formik.touched.description && Boolean(formik.errors.description)
            }
            helperText={formik.touched.description && formik.errors.description}
            margin="normal"
          />
          <Typography component={"p"} className="error" variant="caption">
            {error}
          </Typography>
          <LoadingButton
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            disabled={isLoading}
            loading={isLoading}
            loadingIndicator="Loading…"
          >
            {isEdit ? "Save" : "Continue"}
          </LoadingButton>
        </form>
      </CardContent>
    </Card>
  );
}
