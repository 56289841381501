import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import "yup-phone";
import { client } from "../../common";
import settings from "../../config/settings";
import { setBuisnessBasicInfo, setServices } from "../../redux/action";
import LoadingButton from "@mui/lab/LoadingButton";

const validationSchema = yup.object({
  services: yup
    .array("Please select at-least one service")
    .of(yup.number())
    .required("Please select at-least one service")
    .min(1, "Please select at-least one service category"),
});

export const ServiceItem = ({ onItemPress, services, item, index }) => {
  const { palette } = useTheme();

  const isSelected = useMemo(() => {
    return services.indexOf(item.id) > -1;
  }, [item.id, services]);
  return (
    <Grid item xs={2} sm={4} md={4} onClick={() => onItemPress(item)}>
      <Box
        textOverflow={"ellipsis"}
        overflow="hidden"
        display={"flex"}
        flexDirection="column"
        alignItems={"center"}
        py={2}
        justifyContent="center"
        style={{
          borderRadius: 10,
          borderColor: isSelected ? palette.primary.main : "#CCC",
          borderWidth: isSelected ? 2 : 1,
          borderStyle: "solid",
        }}
      >
        <CardMedia
          image={item.name == "Other" ? item.image : item.image}
          style={{ height: 40, width: 40, resizeMode: "contain" }}
        />
        <Typography mt={1} textAlign="center" noWrap maxWidth={100}>
          {item.name}
        </Typography>
      </Box>
    </Grid>
  );
};

export default function BuisnessArea() {
  const dispatch = useDispatch();
  const history = useHistory();

  // let { path } = useRouteMatch();

  const userData = useSelector((state) => state.auth.userdata);
  const detail = useSelector((state) => state.auth.bBaicInfo);
  const isEdit = false;
  const services = useSelector((state) => state.auth.buisnessServices);

  // const [businessSer, setBusinessSer] = useState(services);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const onSubmit = async (values) => {
    console.log(values);
    const obj = { services: values.services };
    setIsLoading(true);
    try {
      const { data } = await client.put(
        settings.endpoints.updateStore(userData.store_id),
        obj
      );
      setIsLoading(false);
      if (data.success) {
        await dispatch(setBuisnessBasicInfo(data.data));
        // getServices();
        // navigation.navigate('BuisnessDeliveryInfo');
        // analyticsEvent('Update_Business_Details', {
        //   type: 'User updated Store Adresses Page',
        // });
        if (isEdit) {
          history.push(`/business-details/confirmation`);
        } else {
          history.push("/business-details/delivery-info");
        }
      } else {
        console.log("error>>>", data);
      }
    } catch (error) {
      setIsLoading(false);
      setError(error?.response?.message || "Server Error!");
      console.log("error>>>", error, error.response);
    }
  };

  const formik = useFormik({
    initialValues: {
      services: get(detail, "services", []),
    },
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  const { values, handleSubmit, setFieldValue } = formik;

  const getBusinnessServices = useCallback(async () => {
    try {
      const { data } = await client.get(settings.endpoints.getServices);
      if (data.success) {
        dispatch(setServices(data.data));
      }
    } catch (error) {
      console.log("services error", error, error.response);
    }
  }, [dispatch]);

  useEffect(() => {
    getBusinnessServices();
  }, [getBusinnessServices]);

  const dataArray = useMemo(() => {
    return services.filter((i) => !i.is_other);
  }, [services]);

  const onItemPress = useCallback(
    (item) => {
      let tempArr = [...values.services];
      const tIndex = tempArr.indexOf(item.id);
      if (tIndex > -1) {
        tempArr.splice(tIndex, 1);
      } else {
        tempArr.push(item.id);
      }
      setFieldValue("services", tempArr, true);
    },
    [setFieldValue, values.services]
  );

  return (
    <Card style={{ display: "flex", flexDirection: "column", width: 453 }}>
      <CardContent
        style={{ flex: 1, display: "flex", flexDirection: "column" }}
      >
        <Typography variant="h5" component="div" fontWeight={"700"}>
          Area of business
        </Typography>
        <Typography>Select multiple if applicable</Typography>
        <form onSubmit={handleSubmit}>
          <Box my={2}>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              rowSpacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              {dataArray.map((item, index) => (
                <ServiceItem
                  key={`${item.name}_${index}`}
                  item={item}
                  index={index}
                  onItemPress={onItemPress}
                  services={values.services}
                />
              ))}
            </Grid>
          </Box>

          {formik.touched.services && Boolean(formik.errors.services) && (
            <Typography component={"p"} className="error" variant="caption">
              {formik.errors.services}
            </Typography>
          )}

          {error && (
            <Typography component={"p"} className="error" variant="caption">
              {error}
            </Typography>
          )}
          <LoadingButton
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            disabled={isLoading}
            loading={isLoading}
            loadingIndicator="Loading…"
          >
            {isEdit ? "Save" : "Continue"}
          </LoadingButton>
        </form>
      </CardContent>
    </Card>
  );
}
