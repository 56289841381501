import React from "react";

import logo from "../../images/logo.svg";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useHistory, Link as RouterLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/action";

function Navbar() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { loggedIn } = useSelector((state) => state.auth);
  const userLogout = () => {
    // e.preventDefault();
    history.replace("/");
    dispatch(logout());
  };
  return (
    <AppBar position="static" className="App-header">
      <Toolbar
        style={{ height: 140, alignItems: "center", justifyContent: "center" }}
        className="App-header"
      >
        <Box
          maxWidth={"lg"}
          flex={1}
          display="flex"
          alignItems={"center"}
          justifyContent="space-between"
        >
          <Box height={60} width={193}>
            <RouterLink to={"/"}>
              <CardMedia
                component="img"
                image={logo}
                alt="Localhut"
                className="App-logo"
              />
            </RouterLink>
          </Box>
          <Box>
            {loggedIn ? (
              <Typography
                variant="body2"
                component="div"
                onClick={userLogout}
                style={{ cursor: "pointer" }}
              >
                Logout
              </Typography>
            ) : (
              <Typography variant="h6" component="div">
                Rediscover Local
              </Typography>
            )}
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
export default Navbar;
