import {
  ADD_CATEGORY,
  ADD_DELIVERY_PRICE,
  ADD_PRODUCT,
  ADD_PRODUCT_CATEGORY,
  DELETE_CATEGORY,
  DELETE_DELIVERY_PRICE,
  DELETE_PRODUCT,
  LOGOUT,
  REMOVE_PRODUCT_IMAGE,
  SET_BUISNESS_BASICINFO,
  SET_BUISNESS_SERVICES,
  SET_CATEGORY_LIST,
  SET_DATA,
  SET_DELIVERY_OPTIONS,
  SET_DELIVERY_PRICES,
  SET_PRODUCT_LIST,
  SET_SCREEN,
  SET_STAFF_LIST,
  SET_STATUS_LIST,
  SET_USER,
  SET_USER_ROLES,
  STORE_INFO,
  UPDATE_CATEGORY,
  UPDATE_DELIVERY_OPTION,
  UPDATE_DELIVERY_PRICE,
  UPDATE_PRODUCT,
} from "./types";
import { get } from "lodash";

const initialState = {
  loggedIn: false,
  token: "",
  userdata: {},
  screen: "",
  statusList: [],
  bBaicInfo: {},
  productList: [],
  categories: [],
  storeInfo: {},
  buisnessServices: [],
  deliveryPrices: [],
  staffList: [],
  userRoles: [],
  deliveryOptions: [],
};

export const reducer = (state = initialState, action) => {
  // const { type, payload } = action;
  switch (action.type) {
    case SET_DATA:
      return {
        ...state,
        loggedIn: action.loggedIn,
        token: action.token,
        userdata: action.userdata,
      };

    case LOGOUT:
      return {
        ...state,
        loggedIn: false,
        token: "",
        userdata: "",
        productList: [],
        categories: [],
        storeInfo: {},
        buisnessServices: [],
        bBaicInfo: {},
        deliveryOptions: [],
      };

    case SET_PRODUCT_LIST:
      return {
        ...state,
        productList: action.data,
      };

    case ADD_PRODUCT_CATEGORY:
      return {
        ...state,
        categories: [...state.categories, action.data],
      };

    case ADD_PRODUCT:
      const addedProduct = action.data;
      let allProducts = [addedProduct, ...state.productList];
      // allProducts.forEach(element => {
      //   if (element.id == addedProduct.category_id) {
      //     let elementProducts = element.data;
      //     if (elementProducts && elementProducts.length) {
      //       elementProducts = [addedProduct, ...elementProducts];
      //       element.data = elementProducts;
      //     } else {
      //       element.data = [addedProduct];
      //     }
      //   }
      // });

      return {
        ...state,
        productList: allProducts,
      };

    case UPDATE_PRODUCT:
      let tempArr = [...state.productList];
      const productIndex = tempArr.findIndex((i) => i.id === action.data.id);
      tempArr[productIndex] = action.data;

      // tempArr.forEach(i => {
      //   if (i.id == action.data.category_id) {
      //     const productIndex = i.data.findIndex(i => i.id === action.data.id);
      //     i.data[productIndex] = action.data;
      //   }
      // });
      return {
        ...state,
        productList: tempArr,
      };

    case DELETE_PRODUCT:
      let tempList = [...state.productList];
      tempList = tempList.filter((i) => i.id != action.data.id);
      return {
        ...state,
        productList: tempList,
      };

    case REMOVE_PRODUCT_IMAGE:
      let tempListProduct = [...state.productList];

      // tempListProduct.map(ele => {
      //   if (ele.id === action.data.category_id) {
      //     let findIndex = ele.data.find(e => e.id == action.data.product_id);

      //     if (findIndex) {
      //       let filterImages = Array.from(findIndex.product_images).filter(
      //         e => e.id !== action.data.image_id,
      //       );
      //       findIndex['product_images'] = filterImages;
      //     }

      //     let selectedIndex = ele.data.findIndex(e => e.id === findIndex.id);
      //     ele.data[selectedIndex] = findIndex;
      //   }
      // });

      return {
        ...state,
        productList: [...tempListProduct],
      };

    // Categories

    case ADD_CATEGORY:
      return {
        ...state,
        categories: [action.data, ...state.categories],
      };

    case UPDATE_CATEGORY:
      let tempCatArr = [...state.categories];
      const catIndex = tempCatArr.findIndex((i) => i.id === action.data.id);
      tempCatArr[catIndex] = action.data;
      return {
        ...state,
        categories: tempCatArr,
      };

    case DELETE_CATEGORY:
      let tempCatList = [...state.categories];
      tempCatList = tempCatList.filter((i) => i.id != action.data.id);
      return {
        ...state,
        categories: tempCatList,
      };

    case SET_USER:
      return {
        ...state,
        userdata: action.userdata,
      };

    case SET_SCREEN:
      return {
        ...state,
        screen: action.screen,
      };

    case SET_STATUS_LIST:
      return {
        ...state,
        statusList: action.statusList,
      };

    case SET_BUISNESS_BASICINFO:
      return {
        ...state,
        bBaicInfo: action.data,
      };

    case SET_CATEGORY_LIST:
      return {
        ...state,
        categories: action.data,
      };

    case STORE_INFO:
      return {
        ...state,
        storeInfo: action.data,
      };

    case SET_BUISNESS_SERVICES:
      let allServices = get(action, "data", []);
      return {
        ...state,
        buisnessServices: allServices,
      };

    case SET_STAFF_LIST:
      return {
        ...state,
        staffList: action.data,
      };

    case SET_USER_ROLES:
      return {
        ...state,
        userRoles: action.data,
      };

    case SET_DELIVERY_PRICES:
      return {
        ...state,
        deliveryPrices: action.data,
      };

    case SET_DELIVERY_OPTIONS:
      return {
        ...state,
        deliveryOptions: action.data,
      };

    case ADD_DELIVERY_PRICE:
      return {
        ...state,
        deliveryPrices: [...state.deliveryPrices, action.data],
      };

    case UPDATE_DELIVERY_PRICE:
      let tempDelPrices = [...state.deliveryPrices];
      const price_index = tempDelPrices.findIndex(
        (i) => i.id == action.data.id
      );
      if (price_index > -1) {
        tempDelPrices[price_index] = action.data;
      }
      return {
        ...state,
        deliveryPrices: tempDelPrices,
      };

    case UPDATE_DELIVERY_OPTION:
      let tempDelOptions = [...state.deliveryPrices];
      const option_index = tempDelOptions.findIndex(
        (i) => i.id == action.data.id
      );
      if (price_index > -1) {
        tempDelOptions[option_index] = action.data;
      }
      return {
        ...state,
        deliveryPrices: tempDelPrices,
      };

    case DELETE_DELIVERY_PRICE:
      let tempPrices = [...state.deliveryPrices];
      tempPrices = tempPrices.filter((i) => i.id != action.data);
      return {
        ...state,
        deliveryPrices: tempPrices,
      };

    default:
      return state;
  }
};
