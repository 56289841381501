const settings = {
  baseURL: process.env.REACT_APP_API_BASE_URL,
  isUK: true,
  termsURL: "https://localhut.co.uk/terms_conditions",
  privacyURL: "https://localhut.co.uk/privacy_policy",
  endpoints: {
    login: "/api/admin/login",
    registration: "/api/admin/partner-registration",
    verify: "/api/admin/email-otp-verification",
    forgotPassword: "/api/admin/forgot-password?for=otp",
    verifyForgotPassword: "/api/admin/forgot-password?for=forgot-password",
    me: "/api/admin/me",
    refresh: "/api/admin/refresh",
    deviceToken: "/get-device-token",
    logout: "/api/admin/logout",
    ordersByStore: "/api/admin/orders-by-store",
    ordersStatusList: "/api/admin/orders-status-list",
    orderStatusUpdate: "/api/admin/order-status-update",
    order: (id) => `/api/admin/orders/${id}`,
    getSlot: "/api/admin/get-slots",
    orderUpdate: "/api/admin/order-update",
    bookingByStore: `/api/admin/booking-list`,
    getBookingById: (id) => `/bookings/${id}`,
    updateBooking: (id) => `/api/admin/bookings/${id}`,
    updateStore: (id) => `/api/admin/stores/${id}`,
    getStoreProducts: `/api/admin/product-list`,
    getProductsByStore: `/api/admin/products-by-store`,
    getCategories: (id) => `api/categories-by-store/${id}`,
    addProduct: "/api/admin/products",
    deleteProduct: (id) => `/api/admin/products/${id}`,
    addCategory: "/api/admin/category/create",
    updateCategory: (id) => `/api/admin/category/update/${id}`,
    deleteCategory: (id) => `/api/admin/category/delete/${id}`,
    subCategory: "/api/admin/get-sub-categories-by-store",
    productImageUpload: "/api/admin/product_images",
    getServices: "/api/admin/get-services",
    resendOtp: "/api/admin/resend-otp",
    deleteProductImage: (id) => `/api/admin/product_images/${id}`,
    workingSlots: "/api/admin/working_hours",
    getWorkingHours: (id) => `/api/admin/working_hours/${id}`,
    createDeliveryPrice: `/api/admin/delivery_prices`,
    deleteDeliveryPrice: (id) => `/api/admin/delivery_prices/${id}`,
    getStoreDeliveryPrices: (store_id) =>
      `/api/admin/store-delivery-prices/${store_id}`,
    getDashboardLogs: (store_id) => `/api/admin/store-dashboard/${store_id}`,
    getPdfByOrder: (id) => `/api/admin/order/pdf/${id}`,
    getStaffList: "/api/admin/staff-order/staff-list",
    addStaff: "api/admin/staff-order",
    getUserRoles: "api/admin/store-role-list",
    addNewStaff: "api/admin/staff-add-update",
    removeStaff: "api/admin/store-staff-remove",
    getAppointments: "/api/admin/appointments-by-store",
    getAppointmentDetails: (id) => `/api/admin/appointments/${id}`,
    getStoreSlots: "/api/admin/get-slots",
    updateAppointmentStatus: (id) => `/api/admin/appointments/update/${id}`,
    getApptDashboardLogs: (store_id) =>
      `/api/admin/appointments/dashboards/${store_id}`,
    getOffersByStore: (store_id) => `api/admin/offers/store/${store_id}`,
    createOffer: "/api/admin/offers",
    updateOffer: (id) => `/api/admin/offers/${id}`,
    redeemOffer: `api/admin/redeem_offers`,
    getCategoriesByStore: "api/admin/getcategory",
    getProductsByFilter: "api/admin/productsfilter",
    deleteAccount: "/api/admin/soft_delete/staff",
    getStoreDeliveryOptions: (store_id) =>
      `/api/admin/get-delivery-options/${store_id}`,
    createDeliveryOption: "/api/admin/delivery_option",
    updateDeliveryOption: (id) => `/api/admin/delivery_option/${id}`,
  },
};

export default settings;
