import logo from "./checkBoxes.svg";
import { useHistory } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";

function ResetSuccessfull() {
  let history = useHistory();
  function goAhead() {
    history.push("/login");
  }

  return (
    <Card>
      <CardContent>
        <Box
          mt={2}
          mb={2}
          display="flex"
          alignItems={"center"}
          justifyContent="center"
        >
          <CardMedia image={logo} style={{ width: 250, height: 225 }} />
        </Box>
        <Typography variant="h5" mt={1}>
          Password Reset Successfully
        </Typography>
        <Typography variant="body2" mt={2} mb={4}>
          Your new password is sent to your email.
        </Typography>

        <Button onClick={goAhead} fullWidth variant="contained">
          Login
        </Button>
      </CardContent>
    </Card>
  );

  // style={{height: '80vh'}}
  // return (
  //   <div className="container w-50 mx-auto">
  //     <img className="p-3 m-3" src={logo} style={{ width: "300px" }} />
  //     <h1 className="h1">Password Reset Successfully</h1>
  //     <p className="lead">Your new password is sent to your email.</p>
  //     <button onClick={goAhead} className="btn btn-success btn-lg mt-3">
  //       Login
  //     </button>
  //   </div>
  // );
}
export default ResetSuccessfull;
