import Login from "../login/login";
import Signup from "./signup";
// import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";

function GetStarted() {
  return (
    <Box flex={1} display="flex" alignItems={"center"} justifyContent="center">
      <Grid
        container
        spacing={2}
        display="flex"
        flex={1}
        alignSelf="center"
        alignItems="center"
        justifyContent={"center"}
      >
        <Grid item xs={6} flexDirection="column">
          <Signup />
        </Grid>
        <Grid item xs={6} flexDirection="column">
          <Login />
        </Grid>
      </Grid>
    </Box>
  );
}
export default GetStarted;
