import logo from "./chill.svg";
import { useHistory } from "react-router-dom";

function GoBack() {
  let history = useHistory();
  function gotoHome() {
    history.push("/");
  }
  return (
    <div className="d-flex flex-center center-div">
      <div className="register">
        <div className="p-50">
          <img className="p-3 m-3" src={logo} style={{ width: "300px" }} />
          <h1 className="h1">Thanks</h1>
          <p className="font-16">
            You are partner now on Localhut.
          </p>
          <p className="font-16">
            We have sent your login details on your registered email along with
            password.
          </p>
          <p className="font-16">
            Login now to move forward to sell on Localhut.
          </p>
          <button onClick={gotoHome} className="btn btn-success btn-block mt-3">
            Login Now
          </button>
        </div>
      </div>
    </div>
  );
}

export default GoBack;
