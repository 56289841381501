import React from "react";
import { Link as RouterLink } from "react-router-dom";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import background from "./background.png";
import cardImage from "./card-image.png";
import signupLogo from "../../images/signup-logo.svg";

export default function Promotion() {
  return (
    <Container
      maxWidth="xl"
      style={{
        background: `url(${background})`,
        height: "100%",
        width: "100%",
        paddingLeft: 0,
        paddingRight: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        maxWidth: "unset",
      }}
    >
      <Card
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <CardContent style={{ flex: 1, display: "flex", flexDirection: "row" }}>
          <Box>
            <CardMedia component="img" alt="Card Image" image={cardImage} />
          </Box>
          <Box
            padding={3}
            flexDirection={"column"}
            display="flex"
            justifyContent={"space-between"}
          >
            <Box
              flexDirection={"column"}
              display="flex"
              flex={1}
              alignItems={"flex-start"}
            >
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                fontWeight={"700"}
              >
                This Christmas start selling online, FREE
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                mt={4}
                fontWeight={"700"}
              >
                Exclusive Offers - to sell products & services
              </Typography>
              <Typography variant="body2" color="text.secondary" mt={4}>
                No Monthly Cost or Commission to Business Owner.
              </Typography>
              <Typography variant="body2" color="text.secondary" mt={4}>
                We only charge for payment processing fee if there any
                Card/Debit card transactions
              </Typography>
            </Box>

            <Box
              flexDirection={"row"}
              display="flex"
              alignItems={"center"}
              justifyContent="space-between"
            >
              <Box
                flexDirection={"row"}
                display="flex"
                alignItems={"center"}
                justifyContent="center"
              >
                <Typography
                  variant="body2"
                  color="text.secondary"
                  fontWeight={"700"}
                >
                  Supporting Local Business to Be Online
                </Typography>
              </Box>

              <Box
                flexDirection={"row"}
                display="flex"
                alignItems={"center"}
                justifyContent="center"
              >
                <Box mr={2} height="100" width={100}>
                  <CardMedia
                    component="img"
                    alt="User Register"
                    image={signupLogo}
                  />
                </Box>
                <Box>
                  <Link to="/register" component={RouterLink}>
                    <Button size="small" variant="text">
                      Signup Now
                    </Button>
                  </Link>
                </Box>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
}
