
import { useHistory} from 'react-router-dom';
import './businessJourney.css'
import Photo from '../utils/camera-icon.svg'

function BusinessJourney() {
    let history = useHistory();
    
    return (
        <div className="container mt-5 p-3">
            <div className="mx-auto col-md-6 mb-4">
            <h1 className="display-4">Business Details</h1>
            <p className="lead">Provide basic details about your business.</p>
            <hr className="hr" />
                <form className="md-form">
                <div className="file-field mb-3">
                    <div className="mb-1">
                        <img
                        style={{width:"100px"}}
                        src={Photo}
                            className="" alt="example placeholder avatar" />
                    </div>
                    <div className="d-flex ">
                        <div className="btn btn-success btn-sm fileUpload">
                            <span>
                                Add Logo
                            </span>
                            <input type="file" className="upload" accept="image/png, image/jpeg"/>
                        </div>
                    </div>
                </div>
                <div className="mb-3">
                    {/* <label for="exampleFormControlInput1" className="form-label">Email address</label> */}
                    <input type="text" className="form-control name" id="exampleFormControlInput1" placeholder="Business or Store Name" />
                </div>
                <div className="mb-3">
                    <textarea className="form-control" placeholder="Description" id="exampleFormControlTextarea1" rows="3"></textarea>
                </div>
                <div className="mb-3 co-md-9">
                    <label for="exampleFormControlInput2" className="form-label">Address line 1</label>
                    <input type="text" className="form-control " id="exampleFormControlInput2" placeholder="" />
                </div>
                <div className="mb-3 co-md-9">
                    <label for="exampleFormControlInput3" className="form-label">Address line 2</label>
                    <input type="text" className="form-control " id="exampleFormControlInput4" placeholder="" />
                </div>
                <div className="mb-3 co-md-9">
                    <label for="exampleFormControlInput5" className="form-label">City</label>
                    <input type="text" className="form-control name" id="exampleFormControlInput5" placeholder="" />
                </div>
                <div className="mb-3 co-md-9">
                    <label for="exampleFormControlInput6" className="form-label">PostCode</label>
                    <input type="text" className="form-control " pattern="[0-9]{6}" id="exampleFormControlInput6" placeholder="" />
                </div>
                
                <button className="btn btn-success btn-lg btn-block">Continue</button>
                </form>
            </div>
        </div>
    )
}

export default BusinessJourney;