import * as React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as RightArrow } from "../../images/rightarrow.svg";
import signupLogo from "../../images/signup-logo.svg";

import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const Signup = () => {
  return (
    <Card style={{ height: 450, display: "flex", flexDirection: "column" }}>
      <CardContent
        style={{ flex: 1, display: "flex", flexDirection: "column" }}
      >
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          fontWeight={"700"}
        >
          Get Started
        </Typography>
        <Typography variant="body2" color="text.secondary" mt={4}>
          Reconnect to your local community and sell more
        </Typography>
        <Box mt={4} height="100" width={100}>
          <CardMedia component="img" alt="User Register" image={signupLogo} />
        </Box>

        <Typography variant="body2" color="text.secondary" mt={4}>
          Register with us to create account and provide your business details
          to start.
        </Typography>
      </CardContent>
      <CardActions>
        <Box
          alignItems={"center"}
          justifyContent="flex-end"
          display={"flex"}
          flex={1}
        >
          <Link to="/register">
            <Button size="small" variant="text" endIcon={<RightArrow />}>
              Partner Signup
            </Button>
          </Link>
        </Box>
      </CardActions>
    </Card>
  );
};

export default Signup;
