import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import BuisnessAddressDetails from "./BuisnessAddressDetails";
import BuisnessArea from "./BuisnessArea";
import BuisnessBasicDetails from "./BuisnessBasicDetails";
import BuisnessConfirmation from "./BuisnessConfirmation";
import BuisnessDeliveryInfo from "./BuisnessDeliveryInfo";
import BuisnessPaymentInfo from "./BuisnessPaymentInfo";
import BusinessDeliveryOptions from "./BusinessDeliveryOptions";
import BusinessDeliveryPrices from "./BusinessDeliveryPrices";
import BusinessOtherServices from "./BusinessOtherServices";
import WorkingHours from "./WorkingHours";

export default function BusinessDetails() {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path}>
        <BuisnessBasicDetails />
      </Route>
      <Route path={`${path}/address-details`}>
        <BuisnessAddressDetails />
      </Route>
      <Route path={`${path}/area-details`}>
        <BuisnessArea />
      </Route>
      <Route path={`${path}/other-services`}>
        <BusinessOtherServices />
      </Route>
      <Route path={`${path}/delivery-info`}>
        <BuisnessDeliveryInfo />
      </Route>
      <Route path={`${path}/delivery-prices`}>
        <BusinessDeliveryPrices />
      </Route>
      <Route path={`${path}/delivery-options`}>
        <BusinessDeliveryOptions />
      </Route>
      <Route path={`${path}/working-hours`}>
        <WorkingHours />
      </Route>
      <Route path={`${path}/payment-info`}>
        <BuisnessPaymentInfo />
      </Route>
      <Route path={`${path}/confirmation`}>
        <BuisnessConfirmation />
      </Route>
    </Switch>
  );
}
