// import logo from "./graphic.svg";
import logo from "./graphic2.svg";
import { useHistory } from "react-router-dom";

function Congratulations() {
  let history = useHistory();
  function goAhead() {
    history.push("/");
  }
  return (
    <div className="d-flex flex-center center-div">
      <div className="register">
        <div className="p-50">
          <img className="p-3 m-3" src={logo} style={{ width: "183px" }} />
          <p className="ft-20 bold">Congratulations</p>
          <p className="font-16">
            You have Successfully registered to Localhut Partner.
          </p>
          <p className="font-16">
            We have sent your login details in your registered email along with
            password.
          </p>
          <button onClick={goAhead} className="btn btn-success btn-block mt-3">
            Let's Go
          </button>
        </div>
      </div>
    </div>
  );
}

export default Congratulations;
