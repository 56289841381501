export const isLoggedIn = "isLoggedIn";
export const SET_DATA = "auth/SET_DATA";
export const SET_USER = "auth/SET_USER";
export const SET_SCREEN = "auth/SET_SCREEN";
export const SET_STATUS_LIST = "auth/SET_STATUS_LIST";
export const SET_BUISNESS_BASICINFO = "SET_BUISNESS_BASICINFO";
export const SET_PRODUCT_LIST = "SET_PRODUCT_LIST";
export const SET_CATEGORY_LIST = "SET_CATEGORY_LIST";
export const ADD_PRODUCT = "ADD_PRODUCT";
export const ADD_PRODUCT_CATEGORY = "ADD_PRODUCT_CATEGORY";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const REMOVE_PRODUCT_IMAGE = "REMOVE_PRODUCT_IMAGE";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const STORE_INFO = "STORE_INFO";
export const SET_BUISNESS_SERVICES = "SET_BUISNESS_SERVICES";
export const SET_DELIVERY_PRICES = "SET_DELIVERY_PRICES";
export const ADD_DELIVERY_PRICE = "ADD_DELIVERY_PRICE";
export const UPDATE_DELIVERY_PRICE = "UPDATE_DELIVERY_PRICE";
export const DELETE_DELIVERY_PRICE = "DELETE_DELIVERY_PRICE";
export const SET_STAFF_LIST = "SET_STAFF_LIST";
export const SET_USER_ROLES = "SET_USER_ROLES";
export const LOGOUT = "LOGOUT";
export const SET_DELIVERY_OPTIONS = "SET_DELIVERY_OPTIONS";
export const UPDATE_DELIVERY_OPTION = "UPDATE_DELIVERY_OPTION";
