import React, { useEffect, useRef, useState } from "react";
import _, { get, isArray } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { client } from "../../common";
import settings from "../../config/settings";
import {
  setBuisnessBasicInfo,
  setDeliveryOptions,
  setStoreInfo,
} from "../../redux/action";
import { AddCircle } from "@mui/icons-material";
import DeliveryOption from "./DeliveryOption";
import DeliveryOptionForm from "./DeliveryOptionForm";
import { useHistory } from "react-router-dom";

export default function BusinessDeliveryOptions() {
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const userData = useSelector((state) => state.auth.userdata);
  const deliveryOptions = useSelector((state) => state.auth.deliveryOptions);
  const isEdit = false;
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [isFetching, setFetching] = useState(false);

  useEffect(() => {
    getStoreDeliveryOptions();
    // analyticsEvent("Visit_Business_Details", {
    //   type: "User visits Business Delivery Options Page",
    // });
  }, []);

  const getStoreDeliveryOptions = async () => {
    const endPoint = settings.endpoints.getStoreDeliveryOptions(
      userData?.store_id
    );
    try {
      setFetching(true);
      const response = await client.get(endPoint);
      if (response.data.success) {
        const prices = get(response, "data.data", []);
        dispatch(setDeliveryOptions(prices));
      }
      console.log("Response of delivery options-----", response);
      setFetching(false);
    } catch (error) {
      setFetching(false);
      console.log("Error in store delivery prices-----", error.response);
    }
  };

  const getStoreInfo = async () => {
    try {
      const { data } = await client.get(
        settings.endpoints.updateStore(get(userData, "store_id"))
      );
      if (data.success) {
        let storeInfo = get(data, "data[0]", {});
        storeInfo["services"] = get(data, "data.services", []);
        console.log("Updated Store info-------", storeInfo);
        dispatch(setBuisnessBasicInfo(storeInfo));
        dispatch(setStoreInfo(data.data));
      }
    } catch (error) {
      console.log("errrrr", error);
    }
  };

  const onCreateSuccess = () => {
    getStoreDeliveryOptions();
    getStoreInfo();
  };

  const onUpdateSuccess = () => {
    getStoreDeliveryOptions();
    getStoreInfo();
  };

  const onDeleteSuccess = () => {
    getStoreDeliveryOptions();
    getStoreInfo();
  };

  const openAddForm = () => {
    if (formRef?.current) {
      formRef?.current.openForm();
    }
  };

  const onNext = () => {
    if (isEdit) {
      history.push(`/business-details/confirmation`);
    } else {
      history.push(`/business-details/working-hours`);
    }
  };

  return (
    <>
      <Card style={{ display: "flex", flexDirection: "column", width: 453 }}>
        <CardContent
          style={{ flex: 1, display: "flex", flexDirection: "column" }}
        >
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            fontWeight={"700"}
          >
            Business - Delivery Options
          </Typography>
          <Box
            display={"flex"}
            flexDirection={"row"}
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Typography gutterBottom>
              Add business delivery options details
            </Typography>

            <Button
              color="primary"
              startIcon={<AddCircle />}
              variant="outlined"
              size="small"
              style={{ margin: 0 }}
              onClick={openAddForm}
            >
              ADD
            </Button>
          </Box>
          <Box my={2}>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              rowSpacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              {isArray(deliveryOptions) && deliveryOptions.length > 0 ? (
                deliveryOptions.map((item, index) => (
                  <DeliveryOption
                    key={`${item.name}_${index}`}
                    item={item}
                    index={index}
                    onDeleteSuccess={onDeleteSuccess}
                    onUpdateSuccess={onUpdateSuccess}
                  />
                ))
              ) : (
                <Box
                  my={2}
                  px={3}
                  minHeight={200}
                  alignItems="center"
                  justifyContent={"center"}
                  display="flex"
                >
                  <Typography>
                    Please Add At-least One Delivery Option to Continue
                  </Typography>
                </Box>
              )}
            </Grid>
          </Box>
          <Typography component={"p"} className="error" variant="caption">
            {error}
          </Typography>
          {isArray(deliveryOptions) && deliveryOptions.length > 0 ? (
            <LoadingButton
              color="primary"
              variant="contained"
              fullWidth
              // type="submit"
              disabled={isLoading}
              loading={isLoading}
              loadingIndicator="Loading…"
              onClick={onNext}
            >
              {isEdit ? "Save" : "Continue"}
            </LoadingButton>
          ) : null}
        </CardContent>
      </Card>
      <DeliveryOptionForm ref={formRef} onSuccess={onCreateSuccess} />
    </>
  );
}
