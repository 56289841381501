import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as RightArrow } from "../../../images/rightarrow.svg";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";
import settings from "../../../config/settings";

const validationSchema = yup.object({
  otp: yup
    .string("Please Enter OTP Code")
    .required("OTP code is required")
    .matches(/[0-9]{4}/, "OTP must be numeric and 4 digis only"),
});

function Verify(props) {
  const theme = useTheme();
  const history = useHistory();
  const [apiError, setApiError] = useState("");
  const onSubmit = async (values) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9kZW1vLWh1dC5teWxvY2FsaHV0LmNvbVwvYXBpXC9hZG1pblwvbG9naW4iLCJpYXQiOjE2MTA2NjQ3MDksImV4cCI6MTYxMTUyODcwOSwibmJmIjoxNjEwNjY0NzA5LCJqdGkiOiI0SFI3akhadFBDTnZUUGwyIiwic3ViIjoxLCJwcnYiOiI4N2UwYWYxZWY5ZmQxNTgxMmZkZWM5NzE1M2ExNGUwYjA0NzU0NmFhIiwiaWQiOjEsIm5hbWUiOiJBZG1pbiBBZG1pbiIsInN0b3JlX2lkIjowfQ.bAdEezyhHQHJpSZ-f1iWcZupAhtKZnb940gWIhIwCiY"
      );

      var raw = {
        email: props.apiData.email,
        code: values.otp,
      };

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(raw, null, "\t"),
      };
      const URL = `${settings.baseURL}${settings.endpoints.verify}`;
      const response = await fetch(URL, requestOptions);

      const result = await response.json();
      if (result.success == true) {
        if (result.data.is_verified) {
          history.push("/confirmation");
        } else {
          history.push("/success");
        }
      } else if (result.success === false) {
        console.log(result);
        setApiError(result.message);
      }
    } catch (error) {
      setApiError(
        error?.response?.message ||
          "Unexpected Error!. Please try again after some time."
      );
    }
  };
  const formik = useFormik({
    initialValues: {
      otp: "",
    },
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Card
        style={{
          display: "flex",
          flexDirection: "column",
          width: 453,
          height: 565,
        }}
      >
        <CardContent
          style={{ flex: 1, display: "flex", flexDirection: "column" }}
        >
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            fontWeight={"700"}
            mb={2}
          >
            Verify Email
          </Typography>

          <Typography gutterBottom variant="body1" component="p" mb={2}>
            Enter 4-digit Code sent to Your Email:{" "}
            <Typography
              style={{ color: theme.palette.primary.main }}
              component="span"
            >
              {props.apiData.email}
            </Typography>
          </Typography>

          <TextField
            fullWidth
            id="otp"
            name="otp"
            label="OTP"
            variant="outlined"
            placeholder="Enter OTP"
            // type={"number"}
            value={formik.values.otp}
            onChange={formik.handleChange}
            error={formik.touched.otp && Boolean(formik.errors.otp)}
            helperText={formik.touched.otp && formik.errors.otp}
            margin="normal"
            style={{ height: 48, marginBottom: 32 }}
          />

          <Box
            alignItems={"center"}
            justifyContent="flex-start"
            display={"flex"}
            flex={1}
          >
            <Button size="small" variant="text" startIcon={<RightArrow />}>
              Resend OTP
            </Button>
          </Box>

          <Typography component={"p"} className="error" variant="caption">
            {apiError}
          </Typography>
        </CardContent>
        <CardActions>
          <Box
            alignItems={"center"}
            justifyContent="center"
            display={"flex"}
            flex={1}
          >
            <Button
              color="primary"
              variant="contained"
              type="submit"
              fullWidth
              style={{ width: "50%" }}
            >
              Confirm
            </Button>
          </Box>
        </CardActions>
      </Card>
    </form>
  );
}
export default Verify;
