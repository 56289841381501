import axios from "axios";
import { store } from "../redux/store";
import settings from "../config/settings";
import { get } from "lodash";

const { getState } = store;

const checkStatus = (status) => status >= 200 && status < 300;

const client = axios.create({
  //DEV
  baseURL: settings.baseURL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  validateStatus: checkStatus,
});

// Add token for every request if provided
client.interceptors.request.use(async (config) => {
  let { token } = getState().auth;

  // token =
  //   'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9kZW1vLWh1dC5teWxvY2FsaHV0LmNvbVwvYXBpXC9hZG1pblwvbG9naW4iLCJpYXQiOjE2MDM3MjUyMDUsImV4cCI6MTYwNDU4OTIwNSwibmJmIjoxNjAzNzI1MjA1LCJqdGkiOiJQN1c2akE3dzh4eDlENm5JIiwic3ViIjo0LCJwcnYiOiI4N2UwYWYxZWY5ZmQxNTgxMmZkZWM5NzE1M2ExNGUwYjA0NzU0NmFhIiwiaWQiOjQsIm5hbWUiOiJSb2hpdCBTaW5naCIsInN0b3JlX2lkIjoxNX0.LwM6N_Wxjsdhd2wq9NcHd9mBWJppAoJqBExgabPYSpw';

  if (token) {
    config.headers.authorization = `bearer ${token}`;
  }

  return Promise.resolve(config);
});

client.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // console.log(response);
    return response;
  },
  (error) => {
    // console.log("erro>>>>>", error, error.response);
    const errMessage = get(error.response, "data.error", "")
      ? get(error.response, "data.error", "")
      : get(error.response, "data.message", "Server Error");
    if (error && error.config.method !== "get") {
      if (error.config.url === "/api/admin/forgot-password") {
      } else {
        // alert("Error", errMessage);
        console.log("Error", errMessage);
      }
      // alert(get(error.response, 'data.message', 'Server Error'));
    }
    return Promise.reject(error);
  }
);

export { client };
