import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import { Card, CardContent, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import "yup-phone";
import { client } from "../../common";
import settings from "../../config/settings";
import { setBuisnessBasicInfo } from "../../redux/action";
import { LoadingButton } from "@mui/lab";

const validationSchema = yup.object({
  address_line_1: yup
    .string("Please fill Address Line1")
    .required("Please fill Address Line1"),
  address_line_2: yup.string("Please fill Address Line2").optional(),
  city: yup.string("Please fill City").required("Please fill Postcode"),
  postcode: yup.string("Please fill Postcode").required("Please fill Postcode"),
});

export default function BuisnessAddressDetails() {
  const dispatch = useDispatch();
  const history = useHistory();
  const userData = useSelector((state) => state.auth.userdata);
  const detail = useSelector((state) => state.auth.bBaicInfo);
  const isEdit = false;
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const onSubmit = async (values) => {
    console.log(values);

    const obj = {
      address_line_1: values.address_line_1,
      address_line_2: values.address_line_2,
      city: values.city,
      postcode: values.postcode,
      store_message: "We are delivering in your area.",
      opening_hours: "Mon-Sun: 7am - 9pm",
    };
    setError(null);

    setIsLoading(true);

    try {
      const { data } = await client.put(
        settings.endpoints.updateStore(userData.store_id),
        obj
      );

      setIsLoading(false);
      if (data.success) {
        dispatch(setBuisnessBasicInfo(data.data));
        if (isEdit) {
          history.push(`/business-details/confirmation`);
        } else {
          history.push(`/business-details/area-details`);
        }
        // analyticsEvent("Update_Business_Details", {
        //   type: "User updated Store Adresses Page",
        // });
      } else {
        console.log("error>>>", data);
      }
    } catch (error) {
      setIsLoading(false);
      setError(
        error?.response?.message ||
          "Server Error!. Please try again after some time."
      );
      console.log("error>>>", error, error.response);
    }
  };
  const formik = useFormik({
    initialValues: {
      address_line_1: get(detail, "address_line_1", ""),
      address_line_2: get(detail, "address_line_2", ""),
      city: get(detail, "city", ""),
      postcode: get(detail, "postcode", ""),
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  const { values, touched, errors, handleChange, handleSubmit } = formik;
  return (
    <Card style={{ display: "flex", flexDirection: "column", width: 453 }}>
      <CardContent
        style={{ flex: 1, display: "flex", flexDirection: "column" }}
      >
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          fontWeight={"700"}
        >
          Business Details
        </Typography>
        <Typography gutterBottom>
          Provide basic details about your business
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            id="address_line_1"
            name="address_line_1"
            label="Address Line 1*"
            variant="standard"
            placeholder="Address Line 1*"
            value={values.address_line_1}
            onChange={handleChange}
            error={touched.address_line_1 && Boolean(errors.address_line_1)}
            helperText={touched.address_line_1 && errors.address_line_1}
            margin="normal"
          />

          <TextField
            fullWidth
            id="address_line_2"
            name="address_line_2"
            label="Address Line 2*"
            variant="standard"
            placeholder="Address Line 2*"
            value={values.address_line_2}
            onChange={handleChange}
            error={touched.address_line_2 && Boolean(errors.address_line_2)}
            helperText={touched.address_line_2 && errors.address_line_2}
            margin="normal"
          />

          <TextField
            fullWidth
            id="city"
            name="city"
            label="City*"
            variant="standard"
            placeholder="City*"
            value={values.city}
            onChange={handleChange}
            error={touched.city && Boolean(errors.city)}
            helperText={touched.city && errors.city}
            margin="normal"
          />

          <TextField
            fullWidth
            id="postcode"
            name="postcode"
            label="Post Code*"
            variant="standard"
            placeholder="Post Code*"
            value={values.postcode}
            onChange={handleChange}
            error={touched.postcode && Boolean(errors.postcode)}
            helperText={touched.postcode && errors.postcode}
            margin="normal"
          />
          <Typography component={"p"} className="error" variant="caption">
            {error}
          </Typography>
          <LoadingButton
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            disabled={isLoading}
            loading={isLoading}
            loadingIndicator="Loading…"
          >
            {isEdit ? "Save" : "Continue"}
          </LoadingButton>
        </form>
      </CardContent>
    </Card>
  );
}
