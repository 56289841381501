import React, { useState } from "react";
import _, { get } from "lodash";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useFormik } from "formik";
import * as yup from "yup";
import "yup-phone";
import { radius } from "../../config/staticData";
import { client } from "../../common";
import settings from "../../config/settings";
import { setBuisnessBasicInfo } from "../../redux/action";

const validationSchema = yup.object({
  offer_delivery: yup.bool(),
  offer_collection: yup.bool(),
  delivery_radius: yup
    .number("Please Select valid delivery radius")
    .required("Please Select valid delivery radius")
    .integer("Please Select valid delivery radius")
    .default(1),
  min_order_value: yup
    .number("Please Enter valid integer")
    .required("Please Enter valid integer")
    .integer("Please Enter valid integer")
    .default(1),
});

// const validationSchema2 = yup.object().shape({
//   service: yup.object().shape(
//     {
//       offer_delivery: yup.string().when("offer_collection", {
//         is: false,
//         then: yup.bool().required("Please check at-least one service"),
//         otherwise: yup.bool(),
//       }),
//       offer_collection: yup.string().when("offer_delivery", {
//         is: false,
//         then: yup.bool().required("Please check at-least one service"),
//         otherwise: yup.bool(),
//       }),
//     },
//     ["offer_collection", "offer_delivery"]
//   ),
// });

export default function BuisnessDeliveryInfo() {
  const dispatch = useDispatch();
  const history = useHistory();

  const userData = useSelector((state) => state.auth.userdata);
  const detail = useSelector((state) => state.auth.bBaicInfo);
  const deliveryOptions = useSelector((state) => state.auth.deliveryOptions);
  const isEdit = false;

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const onSubmit = async (values) => {
    console.log(values);
    const obj = {
      offer_delivery: values.offer_collection,
      offer_collection: values.offer_delivery,
      delivery_radius: values.delivery_radius,
      min_order_value: values.min_order_value,
      slot_interval: values.slot_interval,
      slot_interval_type: values.slot_interval_type === "Fixed" ? 0 : 1,
      is_future_order: values.is_future_order,
      future_order_days: !values.is_future_order ? 0 : values.future_order_days,
    };
    setError(null);
    setIsLoading(true);
    try {
      const { data } = await client.put(
        settings.endpoints.updateStore(userData.store_id),
        obj
      );
      setIsLoading(false);
      if (data.success) {
        dispatch(setBuisnessBasicInfo(data.data));
        if (isEdit) {
          history.push(`/business-details/confirmation`);
        } else {
          history.push(`/business-details/delivery-options`);
        }
        // analyticsEvent('Update_Business_Details', {
        //   type: 'User updated Business Delivery Details',
        // });
      }
    } catch (error) {
      setIsLoading(false);
      setError(
        error?.response?.message ||
          "Server Error!. Please try again after some time."
      );
      console.log("error>>>", error, error.response);
    }
  };

  const formik = useFormik({
    initialValues: {
      offer_delivery: get(detail, "offer_delivery", true),
      offer_collection: get(detail, "offer_collection", true),
      delivery_radius: _.toNumber(get(detail, "delivery_radius", 1)),
      min_order_value: _.toNumber(get(detail, "min_order_value", 1)),
      slot_interval: _.toNumber(get(detail, "slot_interval", "")),
      slot_interval_type:
        // eslint-disable-next-line eqeqeq
        get(detail, "slot_interval_type") == 0 ? "Fixed" : "Range",
      is_future_order: get(detail, "is_future_order", 0),
      future_order_days: get(detail, "future_order_days", "0"),
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  const { values, touched, errors, handleChange, handleSubmit, setFieldValue } =
    formik;

  const handleCheckbox = (e) => {
    const key = e.target.name;
    setFieldValue(key, !values[key], true);
  };

  const checkError = (key) => {
    return touched[key] && Boolean(errors[key]);
  };

  const isError =
    checkError("offer_collection") || checkError("offer_delivery");
  return (
    <Card style={{ display: "flex", flexDirection: "column", width: 453 }}>
      <CardContent
        style={{ flex: 1, display: "flex", flexDirection: "column" }}
      >
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          fontWeight={"700"}
        >
          Operational details
        </Typography>
        <Typography gutterBottom>
          Provide delivery and collection details
        </Typography>
        <form onSubmit={handleSubmit}>
          <Box flexDirection="column" display={"flex"}>
            <FormControl
              required
              error={isError}
              component="fieldset"
              sx={{ my: 1 }}
              variant="standard"
            >
              <FormLabel component="legend">Select your services</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.offer_collection}
                      onChange={handleCheckbox}
                      name="offer_collection"
                    />
                  }
                  label="Collection"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.offer_delivery}
                      onChange={handleCheckbox}
                      name="offer_delivery"
                    />
                  }
                  label="Delivery"
                />
              </FormGroup>
              {errors.offer_collection || errors.offer_delivery ? (
                <FormHelperText
                  component={"p"}
                  className="error"
                  variant="caption"
                >
                  {errors.offer_collection || errors.offer_delivery}
                </FormHelperText>
              ) : null}
            </FormControl>

            <FormControl sx={{ my: 1 }} error={checkError("delivery_radius")}>
              <InputLabel id="delivey-radius-label">Delivery Radius</InputLabel>
              <Select
                labelId="delivey-radius-label"
                id="delivery_radius"
                value={values.delivery_radius}
                label="Delivery Radius"
                name="delivery_radius"
                onChange={handleChange}
              >
                {radius.map((ele, i) => {
                  return (
                    <MenuItem
                      key={`delivery_radius_item_${i}_${ele.value}`}
                      value={ele.value}
                    >
                      {ele.label}
                    </MenuItem>
                  );
                })}
              </Select>
              {touched.delivery_radius && errors.delivery_radius ? (
                <FormHelperText
                  component={"p"}
                  className="error"
                  variant="caption"
                >
                  {errors.delivery_radius}
                </FormHelperText>
              ) : null}
            </FormControl>

            <TextField
              fullWidth
              id="min_order_value"
              name="min_order_value"
              label="Min. Order Value"
              variant="standard"
              type={"number"}
              placeholder="Min. Order Value"
              value={values.min_order_value}
              onChange={handleChange}
              error={touched.min_order_value && Boolean(errors.min_order_value)}
              helperText={
                touched.min_order_value && errors.min_order_value
                  ? errors.min_order_value
                  : undefined
              }
              margin="normal"
            />
          </Box>
          <Typography component={"p"} className="error" variant="caption">
            {error}
          </Typography>
          <LoadingButton
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            disabled={isLoading}
            loading={isLoading}
            loadingIndicator="Loading…"
          >
            {isEdit ? "Save" : "Continue"}
          </LoadingButton>
        </form>
      </CardContent>
    </Card>
  );
}
