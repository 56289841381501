import React, { useEffect, useState } from "react";
import { get } from "lodash";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Card, CardContent, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { client } from "../../common";
import settings from "../../config/settings";
import { setBuisnessBasicInfo, setStoreInfo } from "../../redux/action";

const styles = {
  container: {
    flex: 1,
    display: "flex",
    backgroundColor: "#FFFFFF",
  },
  flexContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 8,
  },
  infoText: {
    fontSize: 16,
    fontWeight: "500",
    // color: colors.black,
    // fontFamily: fonts.Rubik.bold,
  },
  editText: {
    fontSize: 12,
    // color: colors.primary,
    // fontFamily: fonts.montserrat.bold,
  },
  cardContainer: {
    marginVertical: 8,
    marginHorizontal: 2,
    borderRadius: 5,
    padding: 15,
    backgroundColor: "#fff",
    shadowColor: "#000",
    shadowOffset: { width: 1, height: 1 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
    elevation: 5,
  },
  innerText: {
    fontSize: 14,
    marginTop: 5,
    // fontFamily: fonts.Rubik.regular,
  },
  flex: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  checkBox: {
    height: 30,
    width: 30,
    justifyContent: "center",
    alignItems: "center",
    marginRight: 15,
    // backgroundColor: colors.primary,
  },
  storeText: {
    fontSize: 16,
    // color: colors.primary,
    // fontFamily: fonts.Rubik.bold,
  },
  storeContainer: {
    display: "flex",
    height: 60,
    marginBottom: 10,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    // borderWidth: 1,
    // borderColor: colors.primary,
    backgroundColor: "#F5F5F5",
    paddingHorizontal: 10,
    marginTop: 15,
  },
  tickIcon: {
    height: 15,
    width: 15,
  },
};

export default function BuisnessConfirmation() {
  const history = useHistory();
  const dispatch = useDispatch();
  const detail = useSelector((state) => state.auth.bBaicInfo);
  const storeInfo = useSelector((state) => state.auth.storeInfo);
  const userData = useSelector((state) => state.auth.userdata);
  const services = useSelector((state) => state.auth.buisnessServices);

  const [storeActive, setActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCat, setSelectedCat] = useState([]);
  const [isRefreshing, setRefreshing] = useState(false);

  useEffect(() => {
    getServices();
    // analyticsEvent('Visit_Business_Details', {
    //   type: 'User visit Business Confirmation Page',
    // });
    if (get(storeInfo, "[0].is_store_open", null)) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, []);

  const getServices = async () => {
    // try {
    //   const {data} = await client.get(settings.endpoints.getServices);
    //   console.log('services data>>', data);
    //   if (data.success) {
    //     setServices(data.data);
    let preSelectedServices = get(detail, "services", []);
    if (preSelectedServices && preSelectedServices.length) {
      let tempArr = [];
      services.map((e) => {
        if (preSelectedServices.includes(e.id)) {
          tempArr.push(e);
        }
      });
      setSelectedCat(tempArr);
    }
    // }
    // } catch (error) {
    //   console.log('services error', error, error.response);
    // }
  };

  const onNext = async () => {
    setIsLoading(true);
    try {
      const { data } = await client.put(
        settings.endpoints.updateStore(userData.store_id),
        { is_store_open: storeActive }
      );
      setIsLoading(false);
      if (data.success) {
        dispatch(setBuisnessBasicInfo(data.data));
        resetStack();
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const resetStack = () => {
    history.replace("/dashboard");
  };

  const getStoreInfo = async () => {
    try {
      const { data } = await client.get(
        settings.endpoints.updateStore(get(userData, "store_id"))
      );
      if (data.success) {
        let storeInfo = get(data, "data[0]", {});
        storeInfo["services"] = get(data, "data.services", []);
        dispatch(setBuisnessBasicInfo(storeInfo));
        dispatch(setStoreInfo(data.data));
      }
    } catch (error) {
      console.log("errrrr", error);
    }
  };

  const gotoBasicDetails = () => {
    history.push("/business-details");
  };

  return (
    <Card style={{ display: "flex", flexDirection: "column", width: 453 }}>
      <CardContent
        style={{ flex: 1, display: "flex", flexDirection: "column" }}
      >
        {/* <Box style={styles.container}> */}
        {/* <SafeAreaView style={{ backgroundColor: colors.primary }} /> */}
        {/* <CustomHeader
          navigation={navigation}
          isMenu={get(params, 'fromDrawer', false) ? true : false}
          // isBack
          title="Store Details"
        /> */}
        <Box
          style={{
            flex: 1,
            paddingHorizontal: 30,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* STORE LIVE */}

          {/* {get(storeInfo, "is_store_profile_completed", false) &&
        get(storeInfo, "is_category_completed", false) &&
        get(storeInfo, "is_product_completed", false) ? (
          <TouchableOpacity
            onPress={() => setActive(!storeActive)}
            activeOpacity={0.8}
            style={styles.storeContainer}
          >
            <Text style={styles.storeText}>Make Store Live</Text>

            <CustomCheckbox
              isChecked={storeActive}
              onPress={() => setActive(!storeActive)}
            />
          </TouchableOpacity>
        ) : null} */}

          <Box
          // refreshControl={
          //   <RefreshControl
          //     refreshing={isRefreshing}
          //     onRefresh={() => getStoreInfo()}
          //   />
          // }
          // showsVerticalScrollIndicator={false}
          >
            {/*  */}
            <Box
              style={styles.flexContainer}
              flexDirection="row"
              justifyContent={"space-between"}
            >
              <Typography
                // gutterBottom
                variant="h5"
                // component="div"
                fontWeight={"700"}
              >
                Business details
              </Typography>

              <Link to="/business-details" style={styles.editText}>
                {/* <Typography
                  // gutterBottom
                  style={styles.editText}
                  // onClick={gotoBasicDetails}
                > */}
                EDIT
                {/* </Typography> */}
              </Link>

              {/* <Typography style={styles.infoText}></Typography> */}
              {/* <Typography
                style={styles.editText}
                onClick={gotoBasicDetails}
                // onPress={() => navigation.navigate("BasicDetail")}
              >
                EDIT
              </Typography> */}
            </Box>
            <Box style={styles.cardContainer}>
              <Typography style={styles.innerText}>
                {get(detail, "name", "")}
              </Typography>
              <Typography
                style={styles.innerText}
                textOverflow="ellipsis"
                maxHeight={50}
              >
                {get(detail, "description", "")}
              </Typography>
              <Typography style={styles.innerText}>
                {get(detail, "email", "")}
              </Typography>
              <Typography style={styles.innerText}>
                {get(detail, "phone", "")}
              </Typography>
              {selectedCat.length ? (
                <Box style={{ flexDirection: "row" }}>
                  <Typography style={styles.innerText}>
                    Categories :{" "}
                  </Typography>
                  <Typography style={styles.innerText} marginRight={10}>
                    {selectedCat.map((e) => e.name).join(", ")}{" "}
                  </Typography>
                </Box>
              ) : null}
            </Box>
            {/*  */}

            <Box style={styles.flexContainer}>
              <Typography style={styles.infoText}>Address Details</Typography>
              {/* <Text
                style={styles.editText}
                onPress={() =>
                  navigation.navigate('BuisnessAddressDetails', {isEdit: true})
                }>
                EDIT
              </Text> */}
            </Box>
            <Box style={styles.cardContainer}>
              <Typography style={styles.innerText}>
                {get(detail, "address_line_1", "")}
              </Typography>

              {get(detail, "address_line_2") ? (
                <Typography style={styles.innerText}>
                  {get(detail, "address_line_2", "")}
                </Typography>
              ) : null}

              <Typography style={styles.innerText}>
                {get(detail, "city", "")}, {get(detail, "postcode", "")}{" "}
              </Typography>
              <Typography style={styles.innerText}>
                {get(detail, "country", "")}
              </Typography>
            </Box>

            {/*  */}

            <Box style={styles.flexContainer}>
              <Typography style={styles.infoText}>
                Business - Delivery Information
              </Typography>
              {/* <Text
                style={styles.editText}
                onPress={() =>
                  navigation.navigate('BuisnessDeliveryInfo', {isEdit: true})
                }>
                EDIT
              </Text> */}
            </Box>
            <Box style={styles.cardContainer}>
              <Box style={styles.flex}>
                <Typography style={styles.innerText}>Open Options</Typography>
                <Typography style={styles.innerText}>
                  {get(detail, "offer_delivery") === true ? "Delivery" : ""}
                  {get(detail, "offer_delivery") === true && ","}{" "}
                  {get(detail, "offer_collection") === true ? "Collection" : ""}
                </Typography>
              </Box>

              <Box style={styles.flex}>
                <Typography style={styles.innerText}>
                  Delivery Radius
                </Typography>
                <Typography style={styles.innerText}>
                  {get(detail, "delivery_radius")}{" "}
                  {get(detail, "delivery_radius_unit")}
                </Typography>
              </Box>

              <Box style={styles.flex}>
                <Typography style={styles.innerText}>
                  Min Order Value
                </Typography>
                <Typography style={styles.innerText}>
                  {get(detail, "currency_symbol", "")}{" "}
                  {get(detail, "min_order_value")}
                </Typography>
              </Box>

              {/* <Box style={styles.flex}>
                <Text style={styles.innerText}>Slot Interval</Text>
                <Text style={styles.innerText}>
                  {get(detail, 'slot_interval')} Min
                </Text>
              </Box>

              <Box style={styles.flex}>
                <Text style={styles.innerText}>Slot Interval Type</Text>
                <Text style={styles.innerText}>
                  {get(detail, 'slot_interval_type') == 1 ? 'Range' : 'Fixed'}
                </Text>
              </Box>

              <Box style={styles.flex}>
                <Text style={styles.innerText}>Future Order</Text>
                <Text style={styles.innerText}>
                  {get(detail, 'is_future_order') == 1 ? 'Yes' : 'No'}
                </Text>
              </Box>

              <Box style={styles.flex}>
                <Text style={styles.innerText}>Future Order Days</Text>
                <Text style={styles.innerText}>
                  {get(detail, 'future_order_days')} Days
                </Text>
              </Box> */}
            </Box>

            {/* Delivery Price */}
            {/* <Box style={[styles.flexContainer]}>
              <Text style={styles.infoText}>Business - Delivery Prices</Text> */}
            {/* <Text
                style={styles.editText}
                onPress={() =>
                  navigation.navigate('BusinessDeliveryPrices', {isEdit: true})
                }>
                EDIT
              </Text> */}
            {/* </Box> */}
            {/* <Box style={styles.cardContainer}>
              <Box style={styles.flex}>
                <Text style={styles.innerText}>Delivery Price</Text>
                <Text style={styles.innerText}>
                  {get(detail, 'currency_symbol', '')}
                  {get(detail, 'xdelivery_prices[0].delivery_price', '')}
                </Text>
              </Box>

              <Box style={styles.flex}>
                <Text style={styles.innerText}>Min Order Value</Text>
                <Text style={styles.innerText}>
                  {get(detail, 'currency_symbol', '')}
                  {get(detail, 'xdelivery_prices[0].min_order_value', '')}
                </Text>
              </Box>

              <Box style={styles.flex}>
                <Text style={styles.innerText}>Delivery Radius</Text>
                <Text style={styles.innerText}>
                  {get(detail, 'xdelivery_prices[0].radius', '')}{' '}
                  {get(detail, 'delivery_radius_unit', '')}
                </Text>
              </Box>

              {get(detail, 'xdelivery_prices.length', 0) > 1 ? (
                <Text
                  style={[
                    styles.innerText,
                    {
                      color: colors.primary,
                      textAlign: 'right',
                      marginVertical: 5,
                    },
                  ]}>
                  {`${get(detail, 'xdelivery_prices.length', 0) - 1} more`}
                </Text>
              ) : null}
            </Box> */}

            {/*  */}

            {/* <Box style={[styles.flexContainer]}>
              <Text style={styles.infoText}>Business - Working Hours</Text> */}
            {/* <Text
                style={styles.editText}
                onPress={() =>
                  navigation.navigate('WorkingHours', {isEdit: true})
                }>
                EDIT
              </Text> */}
            {/* </Box> */}

            {/* <Box style={styles.cardContainer}>
              {get(detail, 'xworking_hours', []).map((e, i, array) => {
                return (
                  <Box key={i} style={styles.flex}>
                    {i ===
                    array.findIndex(item => item.weekday === e.weekday) ? (
                      <Text style={styles.innerText}>
                        {getWeekDays(e.weekday)}
                      </Text>
                    ) : (
                      <Text />
                    )}

                    {e.status ? (
                      <Text style={[styles.innerText, {textAlign: 'left'}]}>
                        {moment(e.opening_time, 'HH:mm:ss').format('h:mm A')} -{' '}
                        {moment(e.closing_time, 'HH:mm:ss').format('h:mm A')}
                      </Text>
                    ) : null}
                  </Box>
                );
              })}
            </Box> */}

            {/*  */}

            <Box style={styles.flexContainer}>
              <Typography style={styles.infoText}>
                Business - Payment Information
              </Typography>
              {/* <Text
                style={styles.editText}
                onPress={() => navigation.navigate('BuisnessPaymentInfo')}>
                EDIT
              </Text> */}
            </Box>
            <Box style={styles.cardContainer}>
              <Box style={styles.flex}>
                <Typography style={styles.innerText}>
                  Payment Methods
                </Typography>
                <Typography style={styles.innerText}>
                  {get(detail, "is_payment_card") == 1 ? "Card" : ""}
                  {get(detail, "is_digital_payments", false)
                    ? ", Digital, "
                    : ""}
                  {get(detail, "is_payment_cod") == 1 ? "COD" : ""}
                </Typography>
              </Box>
            </Box>
          </Box>
          {/* <CustomButton
          isLoading={isLoading}
          title="Save"
          containerStyle={{ width: "100%", marginVertical: 20 }}
          onPress={() => {
            storeInfo.is_store_profile_completed ? onNext() : resetStack();
          }}
        /> */}

          <LoadingButton
            color="primary"
            variant="contained"
            fullWidth
            onClick={() => {
              storeInfo.is_store_profile_completed ? onNext() : resetStack();
            }}
            disabled={isLoading}
            loading={isLoading}
            loadingIndicator="Loading…"
          >
            {"Save"}
          </LoadingButton>
        </Box>
      </CardContent>
    </Card>
  );
}
